<template>
  <start />
</template>

<script>
  import Start from '../Start';
  import { mapActions } from 'vuex';

  export default {
    name: 'begin-signing',
    components: { Start },
    created() {
      // Immediately close task
      this.updateProcessStep({});
    },
    methods: { ...mapActions('processStep', ['updateProcessStep']) },
  };
</script>

