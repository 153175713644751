<template>
  <div>
    <bb-hero title=" " />
    <bb-card :status="status">
      <h4 class="f-strong text-center f-color-navy">
        {{ $t(`views.${definitionKey}.title`) }}
      </h4>
      <p
        v-if="showDescription"
        class="f-small text-center"
      >
        {{ $t(`views.${definitionKey}.description`) }}
      </p>
      <i18n
        v-else-if="definitionKey === 'viewUndecidedThankYou'"
        :path="`views.${definitionKey}.description`"
        class="f-small text-center"
        tag="p"
      >
        <bb-link :to="'tel:' + $t('footer.phone')">
          {{ $t('footer.phone') }}
        </bb-link>
        <bb-link :to="'mailto:' + $t('footer.email')">
          {{ $t('footer.email') }}
        </bb-link>
      </i18n>

      <bb-button
        display="block"
        mint
        :loading="isLoading"
        uppercase
        class="m-t-25"
        @click="logout"
      >
        {{ $t(`views.${definitionKey}.backToSelfService`) }}
      </bb-button>
    </bb-card>
  </div>
</template>

<script>
  import { BbCard,
           BbHero,
           BbButton,
           BbLink } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'result',
    components: {
      BbCard,
      BbHero,
      BbButton,
      BbLink,
    },
    props: {
      definitionKey: {
        type: String,
        required: true,
        validator(value) {
          // The value must match one of these strings
          return [
            'viewUndecidedThankYou',
            'viewIncomeProofUploadedThankYou',
          ].indexOf(value) !== -1;
        },
      },
    },
    computed: {
      ...mapGetters('processStep', ['isLoading']),
      showDescription() {
        return this.definitionKey === 'viewIncomeProofUploadedThankYou';
      },
      status() {
        const statusMap = {
          viewUndecidedThankYou: 'success',
          viewIncomeProofUploadedThankYou: 'success',
        };

        return statusMap[this.definitionKey];
      },
    },
    methods: { ...mapActions('auth', ['logout']) },
  };
</script>
