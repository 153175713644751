<template>
  <div>
    <bb-hero title=" " />
    <bb-card
      :status="cardStatus"
    >
      <h4 class="f-strong text-center f-color-navy">
        {{ $t(`views.signingCallback.${textType}.title`) }}
      </h4>
      <p
        class="f-small text-center m-b-25"
      >
        {{ $t(`views.signingCallback.${textType}.description`) }}
      </p>

      <bb-button
        v-if="textType !== 'carhp'"
        display="block"
        mint
        :loading="isLoading"
        uppercase
        @click="submit()"
      >
        {{ $t(`views.signingCallback.${textType}.continue`) }}
      </bb-button>
    </bb-card>
  </div>
</template>

<script>
  import { BbCard,
           BbHero,
           BbButton } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'handle-callback',
    components: {
      BbCard,
      BbHero,
      BbButton,
    },
    computed: {
      ...mapGetters('signing', [
        'role',
        'status',
        'textType',
      ]),
      ...mapGetters('processStep', ['isLoading']),
      cardStatus() {
        return this.textType === 'cancel' ? 'alert' : 'success';
      },
    },
    methods: {
      ...mapActions('signing', ['handleContinue']),
      async submit() {
        await this.handleContinue();
      },
    },
  };
</script>
