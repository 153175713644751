import { ui } from '@bigbank/web-loco';
import { stepFlavors } from './mutations';

export const updateUi = async (store, routeName) => {
  let payload;
  if (store.rootGetters['processStep/offerApprovedAdditionalCheck'] && !store.rootGetters['processStep/isRefinancingOffer']) {
    payload = {
      routeName,
      progress: { application: { step: { flavor: stepFlavors.WITH_INCOME_PROOF }}},
    };
  } else if (store.rootGetters['processStep/isRefinancingOffer']) {
    const routes = {
      acceptOffer: 1,
      submitAdditionalData: 1,
      uploadIncomeProof: 2,
      enterLiabilitiesDetails: 3,
    };
    const current = routes[routeName] ?? 5;

    payload = {
      routeName,
      progress: {
        application: {
          step: {
            flavor: stepFlavors.REFINANCING_WITH_INCOME_PROOF,
            current,
          },
        },
      },
    };
  } else {
    payload = { routeName };
  }

  store.commit(`ui/${ui.types.UI_UPDATED}`, payload, { root: true });
};
