export default [
  { 'creditor': '4Finance Oy' },
  { 'creditor': 'Aktia Pankki Oyj' },
  { 'creditor': 'Anyfin Ab Filial I Finland' },
  { 'creditor': 'Aurajoki Nordic Oy' },
  { 'creditor': 'Avida Finans AB (publ) sivuliike Suomessa' },
  { 'creditor': 'Bank Norwegian AS' },
  { 'creditor': 'Blue Finance Oy' },
  { 'creditor': 'Bluestep Bank AB (publ), filial i Finland' },
  {
    'creditor': 'Bondora As, Suomen Sivuliike',
    iban: 'EE',
  },
  { 'creditor': 'Bonum Pankki Oyj' },
  { 'creditor': 'BRAbank ASA' },
  { 'creditor': 'Collector Bank AB, Suomen sivuliike' },
  { 'creditor': 'Creditstar Finland Oy' },
  { 'creditor': 'Danske Bank A/S, Suomen sivuliike' },
  { 'creditor': 'Danske Finance Oy' },
  { 'creditor': 'Dnb Auto Finance Oy' },
  { 'creditor': 'Ecster AB, sivuliiketoiminta Suomessa' },
  { 'creditor': 'Euro24 Finance Oy' },
  { 'creditor': 'Fellow Finance Oyj' },
  { 'creditor': 'Ferratum Finland Oy' },
  { 'creditor': 'Ferratum Bank p.l.c.' },
  { 'creditor': 'Fixura Ab Oy' },
  { 'creditor': 'GCC Capital AB, sivuliike Suomessa' },
  { 'creditor': 'Gf Money Consumer Finance Oy' },
  { 'creditor': 'Handelsbanken' },
  { 'creditor': 'Handelsbanken Rahoitus Oyj' },
  { 'creditor': 'Ikano Bank AB (julk), Suomen sivuliike' },
  { 'creditor': 'Indie Labs Oy' },
  { 'creditor': 'Instabank ASA' },
  { 'creditor': 'Klarna AB' },
  { 'creditor': 'Komplett Bank ASA' },
  { 'creditor': 'Lainaamo Oy' },
  { 'creditor': 'Lähitapiola Rahoitus Oy' },
  { 'creditor': 'My Lender Oy' },
  { 'creditor': 'Nanolaina Oy' },
  { 'creditor': 'Nordax Bank AB (publ.)' },
  { 'creditor': 'Nordea Bank Oyj' },
  { 'creditor': 'Nordea Rahoitus Suomi Oy' },
  { 'creditor': 'Northmill Bank AB, Suomen sivuliike' },
  { 'creditor': 'Oma Säästöpankki Oyj' },
  { 'creditor': 'OP Vähittäisasiakkaat Oyj' },
  { 'creditor': 'OP Yrityspankki Oyj' },
  { 'creditor': 'Osuuspankki' },
  { 'creditor': 'POP Pankki' },
  { 'creditor': 'Re-Po Rahoitus Oy' },
  { 'creditor': 'Resurs Bank AB Suomen sivuliike' },
  { 'creditor': 'S-Pankki Oyj' },
  { 'creditor': 'Saldo Finance Oyj' },
  { 'creditor': 'Santander Consumer Finance Oy' },
  { 'creditor': 'Sav-Rahoitus Oyj' },
  { 'creditor': 'SEB Kort Bank AB, Helsingin sivuliike' },
  { 'creditor': 'ST1 Finance Oy' },
  { 'creditor': 'Svea Ekonomi AB Filial i Finland' },
  { 'creditor': 'Säästöpankki' },
  { 'creditor': 'TF Bank AB, Suomen sivuliike' },
  { 'creditor': 'Telia Finance Finland' },
  { 'creditor': 'Toyota Finance Finland Oy' },
  { 'creditor': 'Ålandsbanken Abp' },
];
