import { ui } from '@bigbank/web-loco';
import { mutations, state, progressFlavor, calculatorFlavor } from './mutations';
import * as actions from './actions';

// Override everything in common UI
const uiModule = ui.moduleBuilder({ language: 'fi' });

const getters = {
  currentStep: state => state.progress.application.step.current,
  stepFlavor: state => state.progress.application.step.flavor,
  currentPage: state => state.progress.page.current,
  maxPage: state => state.progress.page.max,
  backgroundFlavor: state => state.backgroundFlavor,
  isProgressFlavor: state => state.progressFlavor === progressFlavor.PROGRESS_BAR,
  isCalculatorFlavor: state => state.calculatorFlavor === calculatorFlavor.CALCULATOR,
};

export default {
  namespaced: true,
  actions: {
    ...uiModule.actions,
    ...actions,
  },
  state: {
    ...uiModule.state,
    ...state,
  },
  getters: {
    ...uiModule.getters,
    ...getters,
  },
  mutations: {
    ...uiModule.mutations,
    ...mutations,
  },
};
