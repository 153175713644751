<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: true,
      errorText: $t('form.completeToContinue'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    class="financial-details"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="isCoApplicantTask
        ? $t('views.submitFinancialCoApplicant.title')
        : $t('views.financialDetails.title')
      "
      icon="client-finance"
    />
    <bb-form-repeater
      v-model="incomes"
      :add-button-label="$t('components.formRepeater.addTextIncome.label')"
      :row-limit="5"
      hide-initial-row
    >
      <template
        slot="editLabel"
        slot-scope="row"
      >
        <div>
          {{ enumToText(row.model.type) }}
        </div>
      </template>
      <template
        slot="highlights"
        slot-scope="row"
      >
        <div>{{ enumToText(row.model.type) }}</div>
        <div>{{ $n(unlocalizeFiNumber(row.model.declaredGrossMonthlyIncome), 'currency' ) }}</div>
      </template>
      <template
        slot="fields"
        slot-scope="row"
      >
        <bb-radio
          v-model="row.model.type"
          v-validate="'required'"
          type="number"
          :label="$t('application.incomeType.label')"
          :data-vv-as="$t('application.incomeType.label')"
          :name="`income-type-${row.id}`"
          :selected="row.model.type"
          :options="incomeTypeOptions"
        />
        <bb-input
          v-model="row.model.declaredGrossMonthlyIncome"
          v-validate="'required'"
          type="text"
          :label="$t('components.input.grossIncome.label')"
          :data-vv-as="$t('components.input.grossIncome.label')"
          name="gross-income"
          :help-active="true"
          :help-text="$t('form.helpText.grossIncome')"
          add-on="EUR"
          maxlength="5"
          @focus="onRowInputFocus(row.model, 'declaredGrossMonthlyIncome')"
          @blur="onRowInputBlur(row.model, 'declaredGrossMonthlyIncome')"
        />
        <bb-input
          v-model="row.model.declaredNetMonthlyIncome"
          v-validate="`required|netGross:${row.model.declaredGrossMonthlyIncome}|minValue:${netMinIncome}|netGrossEqual:${row.model.declaredGrossMonthlyIncome}`"
          type="text"
          :label="$t('components.input.netIncome.label')"
          :data-vv-as="$t('components.input.netIncome.label')"
          name="net-income"
          :help-active="true"
          :help-text="$t('form.helpText.netIncome')"
          add-on="EUR"
          maxlength="5"
          @focus="onRowInputFocus(row.model, 'declaredNetMonthlyIncome')"
          @blur="onRowInputBlur(row.model, 'declaredNetMonthlyIncome')"
        />
      </template>
    </bb-form-repeater>
    <h4
      id="financial-details-subtitle"
      class="f-gotham-medium text-center p-y-30"
    >
      {{ isCoApplicantTask
        ? $t('views.submitFinancialCoApplicant.subtitle')
        : $t('views.financialDetails.subtitle')
      }}
    </h4>
    <bb-form-repeater
      v-model="liabilities"
      :class="{ 'financial-details__bb-form-repeater--hide-add': selectedNoLiabilities }"
      :add-button-label="$t('components.formRepeater.addTextObligations.label')"
      hide-initial-row
      :row-limit="selectedNoLiabilities ? 1 : 10"
    >
      <template
        slot="highlights"
        slot-scope="row"
      >
        <div>{{ enumToText(row.model.type, false) }}</div>
        <div v-if="!selectedNoLiabilities">
          {{ $n(unlocalizeFiNumber(row.model.declaredAmount), 'currency' ) }}
        </div>
      </template>
      <template
        slot="fields"
        slot-scope="row"
      >
        <legend class="bb-radio__legend">
          <div class="bb-radio__tooltip-wrapper">
            {{ $t('application.obligationType.detail') }}
          </div>
        </legend>
        <bb-select
          v-model="row.model.type"
          v-validate="'required'"
          :options="row.index === 0 && liabilities.length <= 1
            ? liabilityTypeOptions
            : liabilityTypeOptionsWithoutNoLiabilities
          "
          :label="$t('application.obligationType.label')"
          :data-vv-as="$t('application.obligationType.label')"
          name="liabilities-type"
        />
        <template v-if="!selectedNoLiabilities">
          <bb-input
            v-model="row.model.declaredAmount"
            v-validate="'required'"
            type="text"
            :label="$t('components.input.outstandingPayment.label')"
            :data-vv-as="$t('components.input.outstandingPayment.label')"
            name="outstanding-payment"
            :help-active="true"
            :help-text="$t('form.helpText.outstandingPayment')"
            add-on="EUR"
            maxlength="6"
            @focus="onRowInputFocus(row.model, 'declaredAmount')"
            @blur="onRowInputBlur(row.model, 'declaredAmount')"
          />
          <bb-input
            v-model="row.model.declaredMonthlyPayment"
            v-validate="`required|monthlyOutstanding:${row.model.declaredAmount}`"
            type="text"
            :label="$t('components.input.monthlyPayment.label')"
            :data-vv-as="$t('components.input.monthlyPayment.label')"
            name="monthly-payment"
            :help-active="true"
            :help-text="$t('form.helpText.monthlyPayment')"
            add-on="EUR"
            maxlength="4"
            @focus="onRowInputFocus(row.model, 'declaredMonthlyPayment')"
            @blur="onRowInputBlur(row.model, 'declaredMonthlyPayment')"
          />
        </template>
      </template>
    </bb-form-repeater>
    <i18n
      :path="isCoApplicantTask
        ? 'views.submitFinancialCoApplicant.confirmationText'
        : 'views.financialDetails.confirmationText'
      "
      class="align-center f-gotham-medium f-small p-y-20"
      tag="div"
    >
      <bb-link
        to="https://bigbank.fi/dokumentit?main=14&sec&doc=2709"
        target="_blank"
      >
        {{ isCoApplicantTask
          ? $t('views.submitFinancialCoApplicant.confirmationLinkText')
          : $t('views.financialDetails.confirmationLinkText')
        }}
      </bb-link>
    </i18n>
    <div
      v-if="programmaticPixelVisible"
      class="hidden"
    >
      <img
        src="https://secure.adnxs.com/px?id=783261&t=2"
        width="1"
        height="1"
      />
    </div>
  </bb-application-page>
</template>
<script>
  import { BbApplicationPage,
           BbPublicHeader,
           BbRadio,
           BbInput,
           BbFormRepeater,
           BbLink,
           BbSelect } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/client/finance';
  import { incomeTypeOptions,
           liabilityTypeOptions,
           liabilityTypeOptionsWithoutNoLiabilities,
           NET_MIN_INCOME } from '@/constants';
  import { mapActions, mapGetters } from 'vuex';
  import enumsToOptions from '../mixins/enumsToOptions';
  import { unlocalizeFiNumber,
           localizeFiNumber } from '@/util/localize';

  const unlocalizeFinancials = row => {
    Object.keys(row).forEach(key => key !== 'type' && (
      row[key] = unlocalizeFiNumber(row[key])
    ));

    return row;
  };

  export default {
    name: 'financial-details',
    components: {
      BbApplicationPage,
      BbFormRepeater,
      BbPublicHeader,
      BbRadio,
      BbInput,
      BbSelect,
      BbLink,
    },
    mixins: [enumsToOptions],
    props: {
      locale: {
        type: String,
        default: 'fi',
      },
    },
    data() {
      return {
        incomes: [],
        liabilities: [],
        netMinIncome: NET_MIN_INCOME,
        programmaticPixelVisible: false,
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'definitionKey',
      ]),
      incomeTypeOptions() {
        return this.enumsToOptions(incomeTypeOptions, 'application.incomeType');
      },
      liabilityTypeOptions() {
        return this.enumsToOptions(liabilityTypeOptions, 'application.obligationType');
      },
      liabilityTypeOptionsWithoutNoLiabilities() {
        return this.enumsToOptions(
          liabilityTypeOptionsWithoutNoLiabilities,
          'application.obligationType',
        );
      },
      selectedNoLiabilities() {
        return this.liabilities[0] &&
          this.liabilities[0].type === liabilityTypeOptions.noLiabilities || false;
      },
      isCoApplicantTask() {
        return this.definitionKey === 'submitIncomesAndObligationsCoApplicant';
      },
    },
    methods: {
      unlocalizeFiNumber,
      ...mapActions('processStep', [
        'updateProcessStep',
        'updateProcessWithCalculatorData',
      ]),
      enumToText(typeEnum, income = true) {
        if (!typeEnum) return;
        if (income) return this.incomeTypeOptions.find(i => i.value === typeEnum).text;

        return this.liabilityTypeOptions.find(o => o.value === typeEnum).text;
      },
      onRowInputFocus(rowModel, key) {
        const inputValue = isNaN(unlocalizeFiNumber(rowModel[key])) || rowModel[key] === ''
          ? ''
          : `${unlocalizeFiNumber(rowModel[key])}`;

        this.$set(rowModel, key, inputValue);
      },
      onRowInputBlur(rowModel, key) {
        const inputValue = typeof rowModel[key] === 'string' && rowModel[key].trim() === ''
          ? ''
          : localizeFiNumber(rowModel[key]);

        this.$set(rowModel, key, inputValue);
      },
      submit() {
        if (this.config.ENVIRONMENT === 'production') {
          this.programmaticPixelVisible = true;
        }

        const liabilities = this.selectedNoLiabilities ? [] : this.liabilities.map(unlocalizeFinancials);
        const roleInApplication = this.isCoApplicantTask ? 'coApplicant' : 'applicant';

        const application = {
          [roleInApplication]: {
            allowRegistryCheck: true,
            agreeToPersonalDataProcessing: true,
            incomes: this.incomes.map(unlocalizeFinancials),
            liabilities,
          },
        };

        this.updateProcessWithCalculatorData({ application });
      },
    },
  };
</script>

<style lang="scss">
  .repeater__counter {
    display: none !important;
  }

  .financial-details {
    &__bb-form-repeater {
      &--hide-add .form-repeater__add {
        display: none;
      }
    }
  }
</style>
