import { SET_PRODUCT_DATA } from '@bigbank/web-loco/src/store/modules/products/types';
import router from '../../../router';
import { mapProductTypeFromQuery, loanPurposeOptions } from '../../../constants';
import invert from 'lodash.invert';
import { trimRange } from '../../../util/helpers';

const queryFromProductType = invert(mapProductTypeFromQuery);
const queryFromLoanPurpose = invert(loanPurposeOptions);

export const setProductData = (
  { state, commit },
  { amount, period, productType, loanPurpose }) => {
  // Jest sees root module, but browser doesn't. Replace with getter?
  const productsState = state.products.products
    ? state.products
    : state;

  // Defaults
  amount = amount || productsState.product.amount;
  period = period || productsState.product.period;
  productType = productType || productsState.product.productType;
  loanPurpose = loanPurpose || productsState.product.loanPurpose;

  const product = productType && productsState.products.find(
    product => product.productType === productType,
  ) || productsState.defaultProduct;

  const { minAmount, maxAmount, minPeriod, maxPeriod } = product;

  amount = trimRange(amount, minAmount, maxAmount);
  period = trimRange(period, minPeriod, maxPeriod);

  const productData = {
    amount,
    period,
    productType,
    loanPurpose,
  };

  commit(SET_PRODUCT_DATA, productData);

  setParams(productData);
};

const setParams = ({ productType, amount, period, loanPurpose }) => {
  const params = {
    ...router.currentRoute.query,
    amount,
    period,
    product: queryFromProductType[productType],
    purpose: queryFromLoanPurpose[loanPurpose],
  };

  history.pushState(
    {},
    null,
    `${router.currentRoute.path
    }?${
      Object.keys(params)
        .filter(key => {
          return typeof params[key] !== 'undefined';
        })
        .map(key => {
          return (
            `${encodeURIComponent(key) }=${ encodeURIComponent(params[key])}`
          );
        })
        .join('&')}`,
  );
};

export const setProductDataFromUrl = async ({ dispatch }) => {
  const { amount, period, product, purpose } = router.currentRoute.query;
  const productType = mapProductTypeFromQuery[product];
  const loanPurpose = productType === mapProductTypeFromQuery.refin ? loanPurposeOptions.combiningLoans : loanPurposeOptions[purpose];

  await dispatch('products/setProductData', {
    amount,
    period,
    productType,
    loanPurpose,
  }, { root: true });
};

export default {
  setProductData,
  setProductDataFromUrl,
};
