<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: true,
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isPageLoading,
      loading: isPageLoading,
    }"
    @submit="submitWithCaptcha"
  >
    <bb-public-header
      slot="header"
      :title="$t('views.personalDetails.title')"
      icon="client-personal"
    />
    <template v-if="!isPublicScenario">
      <p class="text-center strong m-b-20 color-gray-90 f-small f-gotham-medium">
        {{ $t('views.personalDetails.doubleCheck') }}
      </p>
      <bb-separator
        class="m-b-10"
        dashed
      />
    </template>
    <bb-input
      v-model="firstName"
      v-validate="'required|min:2|max:35|noNumbers'"
      name="first-name"
      :value="personalDetails.firstName"
      :label="$t('application.applicant.firstName.label')"
      :data-vv-as="$t('application.applicant.firstName.label')"
      :readonly="!isPublicScenario"
    />
    <bb-input
      v-model="surname"
      v-validate="'required|min:2|max:35|noNumbers'"
      name="surname"
      :value="personalDetails.surname"
      :label="$t('application.applicant.surname.label')"
      :data-vv-as="$t('application.applicant.surname.label')"
      :readonly="!isPublicScenario"
    />
    <bb-input
      v-model="personalIdentityCode"
      v-validate="{required: true, fiPersonalId: true, olderThan22Years: true}"
      name="personal-identity-code"
      :value="personalDetails.personalIdentityCode"
      :label="$t('application.applicant.personalIdentityCode.label')"
      :data-vv-as="$t('application.applicant.personalIdentityCode.label')"
      :readonly="!isPublicScenario"
    />
    <bb-input
      v-model="email"
      v-validate="'required|email'"
      name="email"
      :label="$t('application.applicant.email.label')"
      :data-vv-as="$t('application.applicant.email.label')"
      :formatter="fixEmail"
    />
    <bb-prefixed-input
      v-model="phone"
      v-validate="'required|fiPhone'"
      disable-prefix
      name="phone"
      :label="$t('application.applicant.phone.label')"
      :help-text="$t('application.applicant.phone.helpText')"
      help-active
      :data-vv-as="$t('application.applicant.phone.label')"
      type="tel"
      :prefixes="prefixes"
    />
    <template>
      <bb-select
        v-if="isPublicScenario"
        id="loan-purpose-submit"
        v-model="loanPurpose"
        v-validate="'required'"
        name="loan-purpose-submit"
        :options="loanPurposeOptions"
        :label="$t('application.loanPurpose.label')"
        :data-vv-as="$t('application.loanPurpose.label')"
        :disabled="isLoanPurposeSelectDisabled"
        @input="onLoanPurposeInput"
      />
      <bb-input
        v-else
        id="loan-purpose"
        v-validate="'required'"
        :value="upsellLoanPurposeText"
        name="loan-purpose"
        :label="$t('application.loanPurpose.label')"
        :data-vv-as="$t('application.loanPurpose.label')"
        :readonly="!isPublicScenario"
      />
    </template>
    <bb-checkbox
      v-if="isPublicScenario"
      class="p-t-20"
      :value="marketingConsent"
      name="marketing-consent"
      background
      @input="marketingConsent = !marketingConsent"
    >
      <i18n
        path="application.marketingConsent"
        tag="div"
      />
    </bb-checkbox>
    <div class="align-center p-t-20">
      <bb-link
        @click="showReadMore = !showReadMore"
      >
        {{ $t('views.personalDetails.readMoreText') }}
      </bb-link>
      <bb-modal
        :visible="showReadMore"
        flavor="user"
        @close="showReadMore = false"
      >
        <i18n
          :path="'application.marketingConsentReadMore'"
          class="align-left p-y-20"
          tag="div"
        >
          <bb-link :to="'tel:' + $t('application.marketingConsentReadMorePhoneLinktext')">
            {{ $t('application.marketingConsentReadMorePhoneLinkText') }}
          </bb-link>
          <bb-link :to="'mailto:' + $t('application.marketingConsentReadMoreEmailLinktext')">
            {{ $t('application.marketingConsentReadMoreEmailLinkText') }}
          </bb-link>
        </i18n>
      </bb-modal>
    </div>
    <i18n
      v-if="isPublicScenario"
      :path="'views.personalDetails.confirmationText'"
      class="align-center f-gotham-medium f-small p-t-20"
      tag="div"
    >
      <bb-link
        to="https://bigbank.fi/dokumentit?main=34&sec&doc=2710"
        target="_blank"
      >
        {{ $t('views.personalDetails.confirmationLinkText') }}
      </bb-link>
    </i18n>
    <div
      v-if="isPublicScenario"
      class="m-t-20 align-center"
    >
      <vue-hcaptcha
        ref="invisibleHcaptcha"
        :sitekey="config.HCAPTCHA_SITE_KEY"
        size="invisible"
        language="fi"
        @error="onCaptchaError"
        @verify="onVerify"
      />
    </div>
  </bb-application-page>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/client/personal';
  import { BbApplicationPage,
           BbPublicHeader,
           BbSeparator,
           BbSelect,
           BbInput,
           BbPrefixedInput,
           BbLink,
           BbModal,
           BbCheckbox } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
  import { loanPurposeOptions,
           purposesOfProduct,
           findProductTypeByLoanPurpose,
           mapProductTypeFromQuery } from '@/constants';
  import enumsToOptions from '../mixins/enumsToOptions';

  export default {
    name: 'personal-details',
    components: {
      BbApplicationPage,
      BbPublicHeader,
      BbSeparator,
      BbInput,
      BbPrefixedInput,
      BbSelect,
      BbLink,
      BbCheckbox,
      BbModal,
      VueHcaptcha,
    },
    mixins: [enumsToOptions],
    data() {
      // Getters unavailable at data init, so accessing state directly
      // Deep clone is necessary to avoid short-circuiting Select's value (Object)
      return {
        ...JSON.parse(JSON.stringify(this.$store.getters['processStep/personalDetails'])),
        marketingConsent: false,
        showReadMore: false,
        isCaptchaLoading: false,
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'personalDetails',
        'isLoading',
        'definitionKey',
      ]),
      ...mapGetters('products', ['getProductData']),
      isPageLoading() {
        return this.isLoading || this.isCaptchaLoading;
      },
      defaultPrefix() {
        return this.prefixes[0];
      },
      prefixes() {
        return ['+358'];
      },
      loanPurposeOptions() {
        return this.enumsToOptions(loanPurposeOptions, 'application.loanPurpose');
      },
      upsellLoanPurpose() {
        return purposesOfProduct[this.personalDetails.productType][0];
      },
      upsellLoanPurposeText() {
        return this.loanPurposeOptions
          .find(option => option.value === this.upsellLoanPurpose)
          .text;
      },
      isPublicScenario() {
        return !this.definitionKey;
      },
      isLoanPurposeSelectDisabled() {
        return this.getProductData.productType === mapProductTypeFromQuery.refin;
      },
    },
    watch: {
      getProductData() {
        this.syncPurposeToProduct();
      },
    },
    created() {
      if (!this.phone.startsWith(this.defaultPrefix)) {
        this.phone = `${this.defaultPrefix} ${this.phone}`;
      } else {
        this.phone = `${this.phone.substr(0, this.defaultPrefix.length)} ${this.phone.substr(this.defaultPrefix.length)}`;
      }
    },
    mounted() {
      const prefilledEditableData = [
        this.phone,
        this.email,
      ];

      if (!this.isPublicScenario && prefilledEditableData.every(s => s !== '')) {
        this.$validator.validateAll();
      }

      this.syncPurposeToProduct();
    },
    methods: {
      ...mapActions('processStep', [
        'updateProcessWithCalculatorData',
        'createWebLoanApplication',
      ]),
      ...mapActions('products', ['setProductData']),
      onLoanPurposeInput(loanPurpose) {
        const productType = findProductTypeByLoanPurpose(loanPurpose);

        this.setProductData({
          productType,
          loanPurpose,
        });
      },
      fixEmail(inputStr) {
        if (inputStr === null) {
          return inputStr;
        }
        const trimmedStr = inputStr.replace(/[\u200B-\u200E\uFEFF]/g, '').trim();

        return trimmedStr.toLowerCase();
      },
      submitWithCaptcha() {
        if (this.isPublicScenario) {
          this.isCaptchaLoading = true;
          this.$refs.invisibleHcaptcha.execute();
        } else {
          this.submit();
        }
      },
      onCaptchaError() {
        this.isCaptchaLoading = false;
      },
      onVerify(token) {
        this.submit(token);
        this.isCaptchaLoading = false;
      },
      async submit(hCaptchaToken) {
        const trimmedEmail = this.email.replace(/[\u200B-\u200E\uFEFF]/g, '').trim().toLowerCase();
        const application = {
          agreeToTerms: true,
          ...this.isPublicScenario && {
            marketingConsent: this.marketingConsent,
            productType: this.getProductData.productType,
          },
          applicant: {
            firstName: this.firstName,
            surname: this.surname,
            personalIdentityCode: this.personalIdentityCode,
            email: trimmedEmail,
            phone: this.phone,
          },
          loanPurpose: this.isPublicScenario
            ? this.loanPurpose
            : this.upsellLoanPurpose,
        };

        this.isPublicScenario
          ? await this.createWebLoanApplication({
            application,
            hCaptchaToken,
          })
          : await this.updateProcessWithCalculatorData({ application });
      },
      syncPurposeToProduct() {
        if (this.isPublicScenario) {
          this.loanPurpose = this.getProductData.loanPurpose;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.no-shadow {
  box-shadow: none;
}
</style>
