<template>
  <div>
    <steps-overview />
    <bb-card>
      <bb-input
        v-model="bankAccountNumber"
        v-validate="'required|iban'"
        framed
        center
        type="string"
        name="bank-account"
        :data-vv-as="$t('views.payment.bankAccountNumber')"
        :label="$t('views.payment.fillBankAccountNumber')"
        :help-active="true"
        :help-text="$t('views.payment.bankAccountHelpText')"
      />
      <bb-radio
        v-validate="'required'"
        label-center
        equal-width
        type="number"
        :value="monthlyPaymentDay"
        name="monthly-payment-day"
        :data-vv-as="$t('views.payment.monthlyPaymentDay')"
        :label="$t('views.payment.monthlyPaymentDay')"
        :options="paymentDayOptions"
        @change="setPaymentDay"
      />
      <bb-checkbox
        v-if="!stateMarketingConsent"
        class="p-t-10"
        background
        :value="marketingConsent"
        name="marketing-consent"
        @input="marketingConsent = !marketingConsent"
      >
        <i18n
          path="application.marketingConsent"
          tag="div"
        >
          <bb-link :to="'tel:' + $t('footer.phone')">
            {{ $t('footer.phone') }}
          </bb-link>
          <bb-link :to="'mailto:' + $t('footer.email')">
            {{ $t('footer.email') }}
          </bb-link>
        </i18n>
      </bb-checkbox>
    </bb-card>

    <bb-form
      class="bb-card bg-none no-shadow p-t-0"
      uppercase-buttons
      :submit-text="$t('form.submit')"
      :error-text="$t('form.errorText')"
      :disabled="isLoading"
      :loading="isLoading"
      @submit="submit"
    />
  </div>
</template>

<script>
  import { BbCard,
           BbRadio,
           BbForm,
           BbCheckbox,
           BbLink,
           BbInput } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import StepsOverview from '../components/StepsOverview';

  const paymentDayOptions = [
    {
      value: 1,
      text: '1',
    },
    {
      value: 5,
      text: '5',
    },
    {
      value: 10,
      text: '10',
    },
    {
      value: 15,
      text: '15',
    },
    {
      value: 20,
      text: '20',
    },
    {
      value: 25,
      text: '25',
    },
  ];

  export default {
    name: 'payment',
    components: {
      StepsOverview,
      BbCard,
      BbInput,
      BbRadio,
      BbCheckbox,
      BbLink,
      BbForm,
    },
    data() {
      // getters unavailable at data init, so accessing state directly
      return {
        ...this.$store.getters['processStep/paymentData'],
        paymentDayOptions,
      };
    },
    computed: {
      ...mapGetters('processStep', {
        stateMarketingConsent: 'marketingConsent',
        isLoading: 'isLoading',
      }),
    },
    methods: {
      ...mapActions('processStep', ['updateProcessStep']),
      setPaymentDay(value) {
        // bb-radio returns string, even if option value is number
        this.monthlyPaymentDay = parseInt(value, 10);
      },
      submit() {
        const application = {
          applicant: { bankAccountNumber: this.bankAccountNumber },
          marketingConsent: this.marketingConsent,
          monthlyPaymentDay: this.monthlyPaymentDay,
        };
        this.updateProcessStep({ application });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .no-shadow {
    box-shadow: none;
  }
</style>
