<template>
  <bb-offer-thank-you-page
    ref="offer"
    :form-props="{
      submitText: $t('form.submit'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        title=" "
      />
    </template>

    <p class="f-small text-center m-b-15">
      {{ $t('views.involveCoBorrowerOrUseRefinancing.description') }}
    </p>

    <bb-detailed-radio
      v-model="radioValue"
      :options="options"
      name="radio"
    />
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero,
           BbOfferThankYouPage,
           BbDetailedRadio } from '@bigbank/interface-components';
  import { mapActions } from 'vuex';

  export default {
    name: 'co-borrower-refinancing-choice',
    components: {
      BbHero,
      BbOfferThankYouPage,
      BbDetailedRadio,
    },
    data() {
      return {
        isLoading: false,
        radioValue: 1,
        options: [
          {
            value: 1,
            leftColumn: { label: this.$t('views.involveCoBorrowerOrUseRefinancing.optionOne') },
            extra: { description: this.$t('views.involveCoBorrowerOrUseRefinancing.optionOneExtra') },
            id: 'involveCoBorrower',
          },
          {
            value: 2,
            leftColumn: { label: this.$t('views.involveCoBorrowerOrUseRefinancing.optionTwo') },
            extra: { description: this.$t('views.involveCoBorrowerOrUseRefinancing.optionTwoExtra') },
            id: 'useRefinancing',
          },
        ],
      };
    },
    computed: {
      involveCoBorrower() {
        return this.radioValue === 1;
      },
    },
    methods: {
      ...mapActions('processStep', ['updateProcessStep']),
      submit() {
        const application = { involveCoBorrower: this.involveCoBorrower };
        if (this.radioValue === 1) {
          application.hasCoApplicant = true;
        }

        this.updateProcessStep({ application });
      },
    },
  };
</script>
