<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: true,
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="$t('views.coApplicantDetails.title')"
      icon="client-personal"
    />
    <p
      v-if="decisionIsRejectedBringCoBorrower"
      id="rejected-description"
    >
      {{ $t('views.coApplicantDetails.rejectedBringCoBorrower.description') }}
    </p>
    <bb-input
      v-model="application.coApplicant.firstName"
      v-validate="{ required: application.hasCoApplicant, noNumbers: true }"
      name="first-name"
      :label="$t('application.applicant.firstName.label')"
      :data-vv-as="$t('application.applicant.firstName.label')"
    />
    <bb-input
      v-model="application.coApplicant.surname"
      v-validate="{ required: application.hasCoApplicant, noNumbers: true }"
      name="surname"
      :label="$t('application.applicant.surname.label')"
      :data-vv-as="$t('application.applicant.surname.label')"
    />
    <bb-input
      v-model="application.coApplicant.personalIdentityCode"
      v-validate="{ required: application.hasCoApplicant, fiPersonalId: 'fiPersonalId', notApplicant: personalIdentityCode }"
      name="personal-identity-code"
      :label="$t('application.applicant.personalIdentityCode.label')"
      :data-vv-as="$t('application.applicant.personalIdentityCode.label')"
    />
    <bb-input
      v-model="application.coApplicant.email"
      v-validate="{ required: application.hasCoApplicant, email: 'email' }"
      name="email"
      :label="$t('application.applicant.email.label')"
      :data-vv-as="$t('application.applicant.email.label')"
    />
    <bb-prefixed-input
      v-model="application.coApplicant.phone"
      v-validate="{ required: application.hasCoApplicant, fiPhone: 'fiPhone' }"
      name="phone"
      :label="$t('application.applicant.phone.label')"
      :data-vv-as="$t('application.applicant.phone.label')"
      :help-text="$t('application.applicant.phone.helpText')"
      help-active
      type="tel"
      :prefixes="prefixes"
    />
    <field-of-activity
      v-model="application.coApplicant.employment"
      :all-required="application.hasCoApplicant"
    />
    <bb-select
      v-model="application.coApplicant.education"
      v-validate="{ required: application.hasCoApplicant }"
      :options="educationOptions"
      :label="$t('application.education.label')"
      :data-vv-as="$t('application.education.label')"
      name="education-status"
    />
    <bb-checkbox
      v-model="application.coApplicant.nonPolitical"
      background
      class="m-t-30"
      name="non-political"
      :data-vv-as="$t('application.coApplicantNonPolitical.text')"
    >
      {{ $t('application.coApplicantNonPolitical.text') }}
    </bb-checkbox>
  </bb-application-page>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/client/personal';
  import { BbApplicationPage,
           BbSelect,
           BbCheckbox,
           BbPublicHeader,
           BbInput,
           BbPrefixedInput } from '@bigbank/interface-components';
  import FieldOfActivity from '../components/FieldOfActivity';
  import { mapActions, mapGetters } from 'vuex';
  import { educationOptions,
           fieldOfActivityOptions } from '@/constants';
  import enumsToOptions from '../mixins/enumsToOptions';
  import pick from 'lodash.pick';
  import { toApplicationDate } from '@/util/dateFormatter';

  export default {
    name: 'coapplicant-details',
    components: {
      BbApplicationPage,
      BbInput,
      BbPrefixedInput,
      BbSelect,
      BbCheckbox,
      BbPublicHeader,
      FieldOfActivity,
    },
    mixins: [enumsToOptions],
    data() {
      // getters unavailable at data init, so accessing state directly
      return {
        application: {
          coApplicant: {
            firstName: '',
            surname: '',
            personalIdentityCode: '',
            email: '',
            phone: '',
            employment: {
              fieldOfActivity: '',
              workStartDate: '',
              companyName: '',
              companyStartDate: '',
              companyRegistrationCode: '',
            },
            education: '',
            nonPolitical: false,
          },
          hasCoApplicant: true,
        },
        dateMask: '11/1111',
        registrationCodeMask: '1111111-1',
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'decisionIsRejectedBringCoBorrower',
        'personalIdentityCode',
      ]),
      defaultPrefix() {
        return this.prefixes[0];
      },
      prefixes() {
        return ['+358'];
      },
      isPrivateSector() {
        return this.application.coApplicant.employment.fieldOfActivity === fieldOfActivityOptions.privateSector;
      },
      isEntrepreneur() {
        return this.application.coApplicant.employment.fieldOfActivity === fieldOfActivityOptions.entrepreneur;
      },
      isUnemployed() {
        return this.application.coApplicant.employment.fieldOfActivity === fieldOfActivityOptions.unemployed;
      },
      fieldOfActivityOptions() {
        return this.enumsToOptions(fieldOfActivityOptions, 'application.employment.fieldOfActivity');
      },
      educationOptions() {
        return this.enumsToOptions(educationOptions, 'application.education');
      },
    },
    created() {
      this.application.coApplicant.phone =
        `${this.defaultPrefix} ${this.application.coApplicant.phone}`;
    },
    methods: {
      ...mapActions('processStep', ['updateProcessWithCalculatorData']),
      submit() {
        if (!this.application.hasCoApplicant) {
          const removeCoApplicant = { hasCoApplicant: false };
          this.updateProcessWithCalculatorData({ application: removeCoApplicant });
        } else {
          const employmentPicker = {
            [fieldOfActivityOptions.entrepreneur]: [
              'companyRegistrationCode',
              'companyStartDate',
            ],
            [fieldOfActivityOptions.privateSector]: [
              'workStartDate',
              'companyName',
              'employmentContractType',
            ],
            [fieldOfActivityOptions.pensioner]: [],
            [fieldOfActivityOptions.individualActivity]: [],
            [fieldOfActivityOptions.student]: [],
            [fieldOfActivityOptions.unemployed]: [],
          };

          const employment = pick(this.application.coApplicant.employment, [
            'fieldOfActivity',
            ...employmentPicker[this.application.coApplicant.employment.fieldOfActivity],
          ]);

          Object.entries(employment).forEach(([
            key,
            value,
          ]) => {
            if (key === 'workStartDate' || key === 'companyStartDate') {
              employment[key] = toApplicationDate(value);
            }
          });

          const coApplicantApplication = {
            coApplicant: {
              firstName: this.application.coApplicant.firstName,
              surname: this.application.coApplicant.surname,
              personalIdentityCode: this.application.coApplicant.personalIdentityCode,
              email: this.application.coApplicant.email,
              phone: this.application.coApplicant.phone,
              employment,
              education: this.application.coApplicant.education,
              nonPolitical: this.application.coApplicant.nonPolitical,
            },
          };
          this.updateProcessWithCalculatorData({ application: coApplicantApplication });
        }
      },
    },
  };
</script>
