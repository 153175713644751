<template>
  <bb-calculator-modal
    :visible="visible"
    :value="calculatorValues"
    :list-items="calculatorValues.listItems"
    :submit-label="$t('components.calculator.submit')"
    @close="$emit('close')"
    @submit="submit"
    @input="onChange"
  >
    <loan-amount-over-limit-alert
      v-if="showAmountOverLimitAlert"
      slot="amount-alert"
    />
  </bb-calculator-modal>
</template>

<script>
  import { BbCalculatorModal } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import { calculateAprc, calculateMonthlyPayment } from '@bigbank/aprc-calculation';
  import { trimRange } from '@/util/helpers';
  import showAmountOverLimitAlert from '@/mixins/showAmountOverLimitAlert';
  import LoanAmountOverLimitAlert from './LoanAmountOverLimitAlert.vue';

  export default {
    name: 'calculator',
    components: {
      BbCalculatorModal,
      LoanAmountOverLimitAlert,
    },
    mixins: [showAmountOverLimitAlert],
    props: { visible: Boolean },
    data () {
      return {
        calculatorData: {
          amount: 0,
          period: 0,
        },
        loanPaymentData: {
          monthlyPayment: 0,
          apr: 0,
        },
      };
    },
    computed: {
      ...mapGetters('products', [
        'getProductData',
        'isLoading',
      ]),
      ...mapGetters('processStep', ['hasCoApplicant']),
      calculatorValues() {
        return {
          name: 'calculator',
          id: 'header-calculator',
          loader: this.isLoading,
          amountStep: this.calculatorData.amount < 10000 ? 500 : 1000,
          periodStep: 6,
          payment: this.loanPaymentData.monthlyPayment,
          ...this.getProductData,
          listItems: [
            {
              title: this.$t('components.calculator.interestRate'),
              content: `${this.getProductData.interestRate}%`,
            },
            {
              title: this.$t('components.calculator.aprc'),
              content: `${this.loanPaymentData.apr}%`,
            },
          ],
        };
      },
    },
    watch: {
      getProductData () {
        this.initCalculatorValues();
      },
    },
    async created () {
      await this.getProducts();
      await this.setProductDataFromUrl();
      this.initCalculatorValues();
      this.calculateLoanDetails();
    },
    methods: {
      ...mapActions('products', [
        'setProductData',
        'setProductDataFromUrl',
        'getProducts',
      ]),

      onChange (event) {
        this.calculatorData.amount = event.amount;
        this.calculatorData.period = event.period;
        this.calculateLoanDetails();
      },

      initCalculatorValues() {
        const { amount, minAmount, maxAmount, period, minPeriod, maxPeriod } = this.getProductData;

        this.calculatorData = {
          amount: trimRange(amount, minAmount, maxAmount),
          period: trimRange(period, minPeriod, maxPeriod),
        };
      },

      calculateLoanDetails() {
        const maxMaintenanceFee = 12.5;
        const { amount, period } = this.calculatorData;
        const { interestRate, maintenanceFeeRate, contractConclusionFee } = this.getProductData;

        const monthlyPayment = calculateMonthlyPayment(amount, period, interestRate, maxMaintenanceFee, maintenanceFeeRate);
        let apr = 0;
        try {
          apr = calculateAprc(amount, period, monthlyPayment, contractConclusionFee);
          // eslint-disable-next-line no-empty
        } catch (e) {}

        this.loanPaymentData = {
          monthlyPayment,
          apr,
        };
      },

      submit () {
        this.setProductData(this.calculatorData);
        this.$emit('close');
      },
    },
  };
</script>
