/**
 * @mixin Show amount limit alert in different templates
 */
export default {
  computed: {
    showAmountOverLimitAlert() {
      return !this.hasCoApplicant && this.calculatorData?.amount > 25000;
    },
  },
};
