<template>
  <bb-hero
    class="w-100"
    bleed="all"
  >
    <section class="bg-blue">
      <bb-steps-overview>
        <bb-step-icon
          v-for="(step, index) in applicationSteps"
          :key="step.text"
          class="stepper-icon"
          :text="step.text"
          :done="index < currentStep"
          :icon="step.icon"
          :inactive="index > currentStep"
        />
      </bb-steps-overview>
    </section>
  </bb-hero>
</template>

<script>
  import { BbHero, BbStepsOverview, BbStepIcon } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import '@bigbank/interface-components/dist/svg/client/offer';
  import '@bigbank/interface-components/dist/svg/client/details';
  import '@bigbank/interface-components/dist/svg/client/sign';

  export default {
    name: 'steps-overview',
    components: {
      BbHero,
      BbStepsOverview,
      BbStepIcon,
    },
    computed: {
      ...mapGetters('ui', [
        'stepFlavor',
        'currentStep',
      ]),
      applicationSteps() {
        const steps = {
          default: [
            {
              text: this.$t('steps.fillApplication'),
              icon: 'client-details',
            },
            {
              text: this.$t('steps.acceptOffer'),
              icon: 'client-offer',
            },
            {
              text: this.$t('steps.signContract'),
              icon: 'client-sign',
            },
          ],
          withIncomeProof: [
            {
              text: this.$t('steps.fillApplication'),
              icon: 'client-details',
            },
            {
              text: this.$t('steps.acceptOffer'),
              icon: 'client-offer',
            },
            {
              text: this.$t('steps.signContract'),
              icon: 'client-sign',
            },
            {
              text: this.$t('steps.incomeProof'),
              icon: 'client-upload',
            },
          ],
          refinancingWithIncomeProof: [
            {
              text: this.$t('steps.fillApplication'),
              icon: 'client-details',
            },
            {
              text: this.$t('steps.acceptOffer'),
              icon: 'client-offer',
            },
            {
              text: this.$t('steps.incomeProof'),
              icon: 'client-upload',
            },
            {
              text: this.$t('steps.obligationDetails'),
              icon: 'client-offer',
            },
            {
              text: this.$t('steps.signContract'),
              icon: 'client-sign',
            },
          ],
        };

        return steps[this.stepFlavor];
      },
    },
  };
</script>

<style lang="scss" scoped>
 .stepper-icon {
   z-index: 1;
 }
</style>
