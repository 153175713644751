<template>
  <div>
    <p class="m-b-20 f-small text-center">
      {{ $t('components.offerDetailCardRefinancing.refinancingText') }}
    </p>
    <bb-detailed-radio
      name="refinancing-arguments"
      :options="options"
      :value="1"
      single-option
    />
  </div>
</template>

<script>
  import { BbDetailedRadio } from '@bigbank/interface-components';

  export default {
    name: 'offer-details-card-refinancing',
    components: { BbDetailedRadio },
    props: { offer: Object },
    data() {
      return {
        options: [{
          value: 1,
          leftColumn: { label: this.$t('components.offerDetailCardRefinancing.header').toUpperCase() },
          special: {
            enabled: true,
            label: this.$t('components.offerDetailCardRefinancing.special'),
          },
          extra: {
            list: [
              {
                title: this.$t('components.offerDetailCardRefinancing.argument1'),
                props: {
                  titleUppercase: false,
                  bullet: true,
                },
              },
              {
                title: this.$t('components.offerDetailCardRefinancing.argument2'),
                props: {
                  titleUppercase: false,
                  bullet: true,
                },
              },
              {
                title: this.$t('components.offerDetailCardRefinancing.argument3'),
                props: {
                  titleUppercase: false,
                  bullet: true,
                },
              },
            ],
          },
        }],
      };
    },
  };
</script>
