<template>
  <bb-offer-thank-you-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: submitText,
      staticSubmit: cardBackSideVisible,
      disabled: isLoading,
      loading: isLoading
    }"
    :card-props="{
      flipText: $t('views.offer.edit')
    }"
    @submit="submit"
    @cardFlip="cardBackSideVisible = true"
    @cardClose="cardBackSideVisible = false"
  >
    <template slot="header">
      <steps-overview />
    </template>
    <offer-detail-card-refinancing
      v-if="hasRefinancingOffer"
      :offer="offer"
    />
    <offer-detail-card-front
      v-else
      :offer="offer"
    />
    <div
      v-if="!hasRefinancingOffer"
      slot="card-back-side"
    >
      <bb-calculator
        v-model="calculatorData"
        :amount="calculatorData.amount"
        :min-amount="offer.customerMinAmount"
        :max-amount="offer.customerMaxAmount"
        :amount-step="amountStep"
        :period="calculatorData.period"
        :min-period="offer.customerMinPeriod"
        :max-period="offer.customerMaxPeriod"
        :interest-rate="offer.interestRate"
        :period-step="6"
        currency-symbol="€"
        sliders
        name="offer-calculator"
      >
        <loan-amount-over-limit-alert
          v-if="showAmountOverLimitAlert"
          slot="amount-alert"
        />
      </bb-calculator>

      <bb-ticket-stub class="m-t-30 nm-x-30 nm-b-30">
        <bb-button
          display="ticket"
          mint
          :disabled="!calculatorChanged"
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('views.offer.updateOffer') }}
        </bb-button>
      </bb-ticket-stub>
    </div>
    <template slot="form-extras">
      <offer-detail-list :offer="offer" />
      <bb-checkbox
        v-if="nonPolitical === undefined"
        :value="nonPolitical"
        class="p-t-10"
        name="non-political"
        :data-vv-as="$t('application.nonPolitical.label')"
        @input="nonPoliticalToSend = !nonPoliticalToSend"
      >
        {{ $t('application.nonPolitical.text') }}
      </bb-checkbox>
      <bb-checkbox
        v-if="coApplicantPepShown"
        :value="coApplicantNonPolitical"
        class="p-t-10"
        name="co-applicant-non-political"
        :data-vv-as="$t('application.coApplicantNonPolitical.label')"
        @input="coApplicantNonPoliticalToSend = !coApplicantNonPoliticalToSend"
      >
        {{ $t('application.coApplicantNonPolitical.text') }}
      </bb-checkbox>
      <bb-checkbox
        class="p-t-10"
        :value="marketingConsent"
        name="marketing-consent"
        @input="marketingConsent = !marketingConsent"
      >
        <i18n
          path="application.marketingConsent"
          tag="div"
        >
          <bb-link :to="'tel:' + $t('footer.phone')">
            {{ $t('footer.phone') }}
          </bb-link>
          <bb-link :to="'mailto:' + $t('footer.email')">
            {{ $t('footer.email') }}
          </bb-link>
        </i18n>
      </bb-checkbox>
    </template>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbTicketStub,
           BbCalculator,
           BbButton,
           BbCheckbox,
           BbLink, BbOfferThankYouPage } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import showAmountOverLimitAlert from '@/mixins/showAmountOverLimitAlert';
  import StepsOverview from '../components/StepsOverview';
  import OfferDetailCardFront from '../components/OfferDetailCardFront';
  import OfferDetailList from '../components/OfferDetailList';
  import OfferDetailCardRefinancing from '@/components/OfferDetailCardRefinancing';
  import LoanAmountOverLimitAlert from '@/components/LoanAmountOverLimitAlert.vue';

  export default {
    name: 'offer',
    components: {
      OfferDetailList,
      OfferDetailCardFront,
      StepsOverview,
      OfferDetailCardRefinancing,
      BbLink,
      BbTicketStub,
      BbButton,
      BbCalculator,
      BbCheckbox,
      BbOfferThankYouPage,
      LoanAmountOverLimitAlert,
    },
    mixins: [showAmountOverLimitAlert],
    data() {
      // getters unavailable at data init, so accessing state directly
      return {
        calculatorData: {
          amount: this.$store.getters['processStep/offer'].offeredAmount,
          period: this.$store.getters['processStep/offer'].offeredPeriod,
        },
        marketingConsent: this.$store.getters['processStep/marketingConsent'],
        calculatorChanged: false,
        cardBackSideVisible: false,
        nonPoliticalToSend: this.nonPolitical || false,
        coApplicantNonPoliticalToSend: this.coApplicantNonPolitical || false,
        hasRefinancingOffer: this.$store.getters['processStep/isRefinancingOffer'],
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'offer',
        'isLoading',
        'hasCoApplicant',
        'nonPolitical',
        'coApplicantNonPolitical',
        'isRefinancingOffer',
      ]),
      submitText() {
        return this.calculatorChanged ? this.$t('views.offer.updateOffer') : this.$t('form.continue');
      },
      coApplicantPepShown() {
        return this.hasCoApplicant && this.coApplicantNonPolitical === undefined ? true : false;
      },
      amountStep() {
        return this.calculatorData.amount < 10000 ? 500 : 1000;
      },
    },
    watch: {
      'calculatorData.period'() {
        this.calculatorChanged = true;
      },
      'calculatorData.amount'() {
        this.calculatorChanged = true;
      },
    },
    methods: {
      ...mapActions('processStep', ['updateProcessStep']),
      submit() {
        let application = { marketingConsent: this.marketingConsent };
        if (this.nonPolitical === undefined) {
          application = {
            ...application,
            applicant: { nonPolitical: this.nonPoliticalToSend },
          };
        }
        if (this.hasCoApplicant && this.coApplicantNonPolitical === undefined) {
          application = {
            ...application,
            coApplicant: { nonPolitical: this.coApplicantNonPoliticalToSend },
          };
        }
        if (this.calculatorChanged) {
          application = {
            ...application,
            offerChange: {
              loanAmount: this.calculatorData.amount,
              loanMaturity: this.calculatorData.period,
            },
          };
        } else {
          application.offer = { offerAccepted: true };
        }
        this.updateProcessStep({ application });
      },
    },

  };
</script>

<style lang="scss" scoped>
  .no-shadow {
    box-shadow: none;
  }
</style>
