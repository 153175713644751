export const customPostHooks = {
  submitAdditionalPersonalData: (store, processParams) => calculatorHook(store, processParams),
  personalDetails: (store, processParams) => calculatorHook(store, processParams),
  backgroundInformation: (store, processParams) => calculatorHook(store, processParams),
  financialDetails: (store, processParams) => calculatorHook(store, processParams),
  submitAdditionalPersonalDataCoApplicant: (store, processParams) => calculatorHook(store, processParams),
  submitIncomesAndObligationsCoApplicant: (store, processParams) => calculatorHook(store, processParams),
  redirectToSelfService: (store) => {
    store.dispatch('auth/logout', true, { root: true });
  },
};

const calculatorHook = (store, processParams) => {
  const { calculatorData } = store.getters;
  store.dispatch('processStep/defaultPostHook', processParams, { root: true });
  store.dispatch('products/setProductData', calculatorData, { root: true });
};
