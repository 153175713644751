<template>
  <div>
    <div class="text-center">
      <p class="strong f-tiniest uppercase color-gray-90">
        {{ $t('offer.amount') }}
      </p>
      <i18n-n
        id="offered-amount"
        class="strong color-green m-b-20"
        format="currency-no-decimal"
        tag="h2"
        :value="offer.offeredAmount"
      >
        <template #currency="slotProps">
          <span style="font-size: 24px">{{ slotProps.currency }}</span>
        </template>
      </i18n-n>
    </div>

    <bb-ticket-stub class="nm-x-30 nm-b-30 text-center ">
      <div slot="left">
        <p class="strong f-tiniest uppercase color-gray-90">
          {{ $t('offer.monthlyPayment') }}
        </p>
        <p class="strong color-blue">
          {{ $n(offeredMonthlyPayment, 'currency') }} <bb-tooltip>{{ $t('offer.monthlyPaymentTooltip') }}</bb-tooltip>
        </p>
      </div>
      <div>
        <p class="strong f-tiniest uppercase color-gray-90">
          {{ $t('offer.period') }}
        </p>
        <p
          id="offered-period"
          class="strong color-blue"
        >
          {{ offer.offeredPeriod }} {{ $t('components.calculator.period.unit') }}
        </p>
      </div>
    </bb-ticket-stub>
  </div>
</template>

<script>
  import { BbTicketStub, BbTooltip } from '@bigbank/interface-components';

  export default {
    name: 'offer-detail-card-front',
    components: {
      BbTicketStub,
      BbTooltip,
    },
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    computed: {
      offeredMonthlyPayment() {
        return this.offer.offeredMonthlyPayment + this.offer.administrationFee;
      },
    },
  };
</script>
