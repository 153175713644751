import { products } from '@bigbank/web-loco';
import { SET_PRODUCT_DATA } from '@bigbank/web-loco/src/store/modules/products/types';
import { SET_PRODUCT_TYPE } from './types';
import actions from './actions';
import router from '../../../router';
import merge from 'lodash.merge';

// create local state
const defaultProduct = {
  productType: 'LOANFI09',
  minInterestRate: 5.9,
  maxInterestRate: 13.42,
  interestRate: 8.35,
  minAmount: 4001,
  maxAmount: 50000,
  minPeriod: 24,
  maxPeriod: 120,
  contractConclusionFee: 0,
  contractConclusionFeeRate: null,
  maintenanceFee: null,
  maintenanceFeeRate: 0.3,
  maxMaintenanceFee: 12.5,
  administrationFee: 1.5,
};

const state = {
  products: [],
  product: {
    productType: defaultProduct.productType,
    period: 60,
    amount: 9000,
    loanPurpose: '',
  },
  defaultProduct,
  loading: false,
};

const getters = {
  getProductData: state => {
    const matchingProduct = state.products
      .find(product => product.productType === state.product.productType);

    if (!matchingProduct) {
      return {
        ...state.defaultProduct,
        amount: state.product.amount,
        period: state.product.period,
        productType: state.product.productType || '',
        loanPurpose: state.product.loanPurpose,
      };
    }

    return {
      ...matchingProduct,
      amount: state.product.amount,
      period: state.product.period,
      loanPurpose: state.product.loanPurpose,
    };
  },
};

export const mutations = {
  [SET_PRODUCT_TYPE]: (state, product) => {
    state.product.productType = product;
  },
  [SET_PRODUCT_DATA]: (state, { amount, period, productType, loanPurpose }) => {
    if (amount) {
      state.product.amount = Number(amount);
    }
    if (period) {
      state.product.period = Number(period);
    }
    if (productType) {
      state.product.productType = productType;
    }
    if (loanPurpose) {
      state.product.loanPurpose = loanPurpose;
    }
  },
};

export const createProductsModule = client => {
  const productModule = products.moduleBuilder(client, router);

  return {
    ...productModule,
    state,
    mutations: merge(productModule.mutations, mutations),
    getters: merge(productModule.getters, getters),
    actions: merge(productModule.actions, actions),
  };
};
