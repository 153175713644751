import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import VeeValidate from 'vee-validate';
import '@bigbank/interface-components/dist/lib/esm.css';

import { apm, GtmScriptTag } from '@bigbank/web-loco';
import validationMessages from 'vee-validate/dist/locale/en';

import App from './App.vue';
import router from './router';
import store from './store/store';
import i18n from './locales/i18n';
import PortalVue from 'portal-vue';
import VueGtm from 'vue-gtm';

import trackEvents from './plugins/gtm/GtmMiddleware';

Vue.config.productionTip = false;

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  dictionary: { en: validationMessages },
});

Vue.use(PortalVue);

// Variables passed by LOS
Vue.prototype.config = window.config;

if (window.config && window.config.GTM_CLIENT_ID) {
  Vue.use(GtmScriptTag, { id: window.config.GTM_CLIENT_ID });
  Vue.use(VueGtm, {
    id: window.config.GTM_CLIENT_ID,
    enabled: true,
    debug: window.config.ENVIRONMENT !== 'production',
    loadScript: false, // disabled to override script tag generation with GtmScriptTag plugin
  });
  trackEvents(Vue, router, store);
}

sync(store, router);
apm.startApm(router, window.config.APM_ENABLED, window.config.ENVIRONMENT);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
