import iban from 'iban';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { unlocalizeFiNumber } from '../util/localize';

dayjs.extend(customParseFormat);

// VeeValidate custom rules must conform to
// https://baianat.github.io/vee-validate/guide/custom-rules.html

const fiPhoneRegex = /^((90[0-9]{3})?0|\+358\s?)(?!100|20(0|2(0|[2-3])|9[8-9])|300|600|700|708|75(00[0-3]|(1|2)\d{2}|30[0-2]|32[0-2]|75[0-2]|98[0-2]))(4|50|10[1-9]|20(1|2(1|[4-9])|[3-9])|29|30[1-9]|71|73|75(00[3-9]|30[3-9]|32[3-9]|53[3-9]|83[3-9])|2|3|5|6|8|9|1[3-9])\s?(\d\s?){4,12}\d$/;

// Only allows a valid country code without spaces
// but not followed by a premium service number prefix
// instead, followed by a business or mobile number prefix that has no space in between
// Followed by 5-11 digits with a single space anywhere between.
// Does not allow multiple spaces anywhere.
// Country code (except "0"), prefix and suffix can be separated with a single space.

const personalIdControlChars = '0123456789ABCDEFHJKLMNPRSTUVWXY'.split('');

const personalIdHasCorrectChecksum = (input, checksum) => {
  const remainder = Number(input) % 31;

  return personalIdControlChars[remainder] === checksum;
};

const personalIdHasValidDate = (personalCode, cleaned) => {
  const [
    // eslint-disable-next-line no-unused-vars
    _,
    dayStr,
    monthStr,
    yearStr,
  ] = /^(\d{2})(\d{2})(\d{2})/.exec(cleaned);

  const year = parseYearFromPC(personalCode, yearStr);
  const month = Number(monthStr) - 1;
  const day = Number(dayStr);
  const date = new Date(year, month, day);

  const yearIsValid = String(date.getFullYear()).substr(-2) === yearStr;
  const monthIsValid = date.getMonth() === month;
  const dayIsValid = date.getDate() === day;

  return yearIsValid && monthIsValid && dayIsValid;
};

function parseYearFromPC(personalCode, yearStr) {
  const yearSign = personalCode[6];
  let year;

  if (yearSign === 'A') {
    year = `20${yearStr}`;
  } else if (yearSign === '+') {
    year = `18${yearStr}`;
  } else {
    year = `19${yearStr}`;
  }

  return Number(year);
}

const personalIdPattern = /^[0-3]\d[0-1]\d{3}[\-+A]\d{3}[\dABCDEFHJKLMNPRSTUVWXY]$/i;

const personalIdValidator = (value) => {
  if (!personalIdPattern.test(value)) {
    return false;
  }

  const cleaned = value.substr(0, 10).replace(/\D/g, '');
  const checksum = value.substr(-1).toUpperCase();

  return personalIdHasCorrectChecksum(cleaned, checksum) && personalIdHasValidDate(value, cleaned);
};

const smallerOrEqual = (smallerValue, biggerValue) => {
  return unlocalizeFiNumber(smallerValue) <= unlocalizeFiNumber(biggerValue);
};

const isValidBBAN = (iban) => {
  let sum = 0;
  let alternate = false;
  const trimmedIban = iban.replace(/\s+/g, '').trim();
  const bban = trimmedIban.substring(4, trimmedIban.length);

  for (let i = bban.length - 1; i >= 0; i--) {
    let n = bban.substring(i, i + 1);

    if (alternate) {
      n *= 2;
      if (n > 9) {
        n -= 9;
      }
    }
    sum += +n;
    alternate = !alternate;
  }

  return (sum % 10 === 0);
};

export const fiPhoneValidator = {
  name: 'fiPhone',
  validator: { validate: value => fiPhoneRegex.exec(value) !== null },
};

// Error messages defined in locales
export const ibanValidator = {
  name: 'iban',
  validator: {
    validate: value => iban.isValid(value) &&
      !!value.match(/^[0-9A-Z ]*$/i) &&
      value.indexOf('FI') === 0 &&
      isValidBBAN(value),
  },
};

// EE IBAN
export const ibanValidatorEE = {
  name: 'EEiban',
  validator: { validate: value => iban.isValid(value) && /(^EE)/.test(value) },
};

const nextMonth = dayjs().endOf('month');
const rule = /^(0[1-9]|1[0-2])\/([12][09]\d\d)$/;

export const monthYearDateValidator = {
  name: 'monthYear',
  validator: {
    validate: value => dayjs(value, 'MM/YYYY').isBefore(nextMonth) &&
      rule.exec(value) !== null,
  },
};

export const isOlderThan22 = (personalId) => {
  if (personalId && personalId.length === 11) {
    const birthday = personalId.substring(0, 6);
    const centuryMarker = personalId.substring(6, 7);
    let parsedBirthday = '';
    const twentyTwoYearsInMs = 694252372000;

    if (centuryMarker === '+') {
      parsedBirthday = `18${birthday.substring(4, 6)}-${birthday.substring(2, 4)}-${birthday.substring(0, 2)}`;
    }
    if (centuryMarker === '-') {
      parsedBirthday = `19${birthday.substring(4, 6)}-${birthday.substring(2, 4)}-${birthday.substring(0, 2)}`;
    }
    if (centuryMarker === 'A') {
      parsedBirthday = `20${birthday.substring(4, 6)}-${birthday.substring(2, 4)}-${birthday.substring(0, 2)}`;
    }

    return dayjs().diff(parsedBirthday) > twentyTwoYearsInMs;
  }

  return false;
};

export const companyNameValidator = {
  name: 'companyName',
  validator: { validate: value => value.length > 1 && value.length < 100 },
};

export const registrationCodeValidator = {
  name: 'registrationCode',
  validator: { validate: value => !!value.match(/^[0-9]{7}-[0-9]$/) },
};

export const uploadedFilesAreValid = {
  name: 'uploadedFilesAreValid',
  validator: { validate: files => !files.some(file => file.errorFileName) },
};

export const netGrossValidator = {
  name: 'netGross',
  validator: { validate: (net, gross) => smallerOrEqual(net, gross) },
};

export const netGrossEqualValidator = {
  name: 'netGrossEqual',
  validator: { validate: (net, gross) => unlocalizeFiNumber(net) !== unlocalizeFiNumber(gross) },
};

export const monthlyOutstandingValidator = {
  name: 'monthlyOutstanding',
  validator: { validate: (monthly, outstanding) => smallerOrEqual(monthly, outstanding) },
};

export const minValue = {
  name: 'minValue',
  validator: { validate: (netIncome, minNetIncome) => smallerOrEqual(minNetIncome, netIncome) },
};

export const personalIdNumberValidator = {
  name: 'fiPersonalId',
  validator: { validate: personalIdValidator },
};

export const olderThan22Years = {
  name: 'olderThan22Years',
  validator: { validate: isOlderThan22 },
};

export const notApplicant = {
  name: 'notApplicant',
  validator: { validate: (value, applicantPersonalId) => value !== applicantPersonalId[0] },
};

export const ibanShouldNotEqual = {
  name: 'ibanShouldNotEqual',
  validator: {
    validate: (value, iban) => {
      iban = Array.isArray(iban) ? iban : [iban];
      const errors = iban.reduce((a, b) => value === b ? a.concat(b) : a, []);

      return !errors.length;
    },
  },
};

const regexForFirstLastName = /^[^0-9_.,;:"#$€/()%&=?!*@<>{}~]+$/;
export const firstLastNameValidator = {
  name: 'noNumbers',
  validator: { validate: value => regexForFirstLastName.exec(value) !== null },
};

const rules = [
  fiPhoneValidator,
  ibanValidator,
  ibanValidatorEE,
  monthYearDateValidator,
  registrationCodeValidator,
  uploadedFilesAreValid,
  companyNameValidator,
  netGrossValidator,
  netGrossEqualValidator,
  monthlyOutstandingValidator,
  personalIdNumberValidator,
  olderThan22Years,
  firstLastNameValidator,
  minValue,
  notApplicant,
  ibanShouldNotEqual,
];

export default function extend(veeValidate) {
  rules.forEach(rule => veeValidate.extend(rule.name, rule.validator));
}
