import merge from 'lodash.merge';
import { ui } from '@bigbank/web-loco';

export const headerFlavor = { DEFAULT: 'default' };

export const calculatorFlavor = { CALCULATOR: 'calculator' };

export const progressFlavor = { PROGRESS_BAR: 'progressBar' };

export const stepFlavors = {
  DEFAULT: 'default',
  WITH_INCOME_PROOF: 'withIncomeProof',
  REFINANCING_WITH_INCOME_PROOF: 'refinancingWithIncomeProof',
};

export const backgroundFlavors = {
  DEFAULT: 'gray-10',
  WHITE: 'white',
};

export const state = {
  backgroundFlavor: backgroundFlavors.DEFAULT,
  calculatorFlavor: false,
  progressFlavor: false,
  progress: {
    page: {
      current: 5,
      max: 5,
    },
    application: {
      step: {
        current: 1,
        flavor: stepFlavors.DEFAULT,
      },
    },
  },
};

const progressFlavorState = merge({},
  state,
  {
    progressFlavor: progressFlavor.PROGRESS_BAR,
    backgroundFlavor: backgroundFlavors.WHITE,
  },
);

// Each route in the app must define an UI state
const routeUiStates = {
  start: state,
  // Public
  newApplication: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: { page: { current: 1 }},
    },
  ),
  submitAdditionalPersonalData: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: { page: { current: 2 }},
    },
  ),
  submitIncomesAndObligations: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: {
        page: {
          current: 3,
          max: 4,
        },
      },
    },
  ),
  submitAdditionalPersonalDataCoApplicant: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: {
        page: {
          current: 4,
          max: 6,
        },
      },
    },
  ),
  submitIncomesAndObligationsCoApplicant: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: {
        page: {
          current: 5,
          max: 6,
        },
      },
    },
  ),
  // Upsell - Scenario A
  acceptOffer: state,
  submitAdditionalData: state,
  uploadIncomeProof: merge({},
    state,
    {
      progress: {
        application: {
          step: {
            current: 3,
            flavor: stepFlavors.WITH_INCOME_PROOF,
          },
        },
      },
    },
  ),
  uploadIncomeProofSentByEmail: merge({},
    state,
    {
      progress: {
        application: {
          step: {
            current: 3,
            flavor: stepFlavors.WITH_INCOME_PROOF,
          },
        },
      },
    },
  ),
  uploadAdditionalDocs: merge({},
    state,
    {
      progress: {
        application: {
          step: {
            current: 3,
            flavor: stepFlavors.WITH_INCOME_PROOF,
          },
        },
      },
    },
  ),
  // Upsell - Scenario B views
  chooseProduct: merge({},
    progressFlavorState,
    { progress: { page: { current: 1 }}},
  ),
  personalDetails: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: { page: { current: 2 }},
    },
  ),
  backgroundInformation: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: { page: { current: 3 }},
    },
  ),
  financialDetails: merge({},
    progressFlavorState,
    {
      calculatorFlavor: calculatorFlavor.CALCULATOR,
      progress: { page: { current: 4 }},
    },
  ),
  enterLiabilitiesDetails: state,
  viewIncomeProofUploadedThankYou: state,
  involveCoBorrowerOrUseRefinancing: state,
  viewNegativeThankYou: state,
  viewUndecidedThankYou: state,
  maintenance: state,
  error500: state,
  error404: state,
  noTask: state,
  sessionExpired: state,
  unauthorized: state,
  // Signing
  beginSigning: state,
  applicantSigning: state,
  coApplicantSigning: state,
  callbackSigning: state,
};

export const mutations = {
  [ui.types.UI_UPDATED]: (state, { routeName, ...optionalValues }) => {
    const uiState = routeUiStates[routeName];
    // Defend against task renames or random new tasks
    if (!ui) console.error(`No UI state defined for ${routeName}`);
    // create deterministic function here instead of merging
    state = merge(state, uiState, { ...optionalValues });
  },
};

export default mutations;
