<template>
  <div>
    <h4 class="f-case-upper m-b-0">
      {{ $t("offer.information") }}
    </h4>
    <bb-list-group class="clear-side-gutter">
      <bb-list-item
        condensed
        no-top-border
      >
        {{ $t("offer.explanation") }}
      </bb-list-item>
      <bb-list-item
        v-if="!hasRefinancingOffer"
        id="offer-period"
        :title="$t('offer.period')"
        condensed
      >
        <p id="offer-period-val">
          {{ offer.offeredPeriod }} {{ $t('components.calculator.period.unit') }}
        </p>
      </bb-list-item>
      <bb-list-item
        v-if="!hasRefinancingOffer"
        id="conclusion-fee"
        :title="$t('offer.conclusionFee')"
        condensed
      >
        <p id="conclusion-fee-val">
          {{ $n(offer.conclusionFee, 'currency-no-decimal') }}
        </p>
      </bb-list-item>
      <bb-list-item
        v-if="!hasRefinancingOffer"
        id="administration-fee"
        :title="$t('offer.administrationFee')"
        condensed
      >
        <p id="administration-fee-val">
          {{ $n(offer.administrationFee, 'currency') }}
        </p>
      </bb-list-item>
      <bb-list-item
        id="offer-interest"
        :title="$t('offer.interest')"
        condensed
      >
        <p id="offer-interest-val">
          {{ $n(offer.interestRate) }} %
        </p>
      </bb-list-item>
      <bb-list-item
        v-if="!hasRefinancingOffer"
        id="offer-aprc"
        :title="$t('offer.aprc')"
        condensed
      >
        <p id="offer-aprc-val">
          {{ $n(offer.aprc, {maximumFractionDigits: 1}) }} %
        </p>
      </bb-list-item>
      <bb-list-item
        v-if="hasRefinancingOffer"
        :title="$t('components.offerDetailCardRefinancing.detailList.finishApplication')"
        condensed
      />
    </bb-list-group>
  </div>
</template>

<script>
  import { BbListGroup, BbListItem } from '@bigbank/interface-components';

  export default {
    name: 'offer-detail-list',
    components: {
      BbListGroup,
      BbListItem,
    },
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    data () {
      return { hasRefinancingOffer: this.$store.getters['processStep/isRefinancingOffer'] };
    },
  };
</script>
