import axios from 'axios';

class FiLoanOriginationClient {
  static getSigningLink(id) {
    return axios.get(`/api/v1/signing/${id}/link`);
  }

  static signicatCallback(id, role, status) {
    return axios.post(`/api/v1/signing/${id}/signicat/callback?role=${role}&status=${status}`, {});
  }

  static redirect(url) {
    window.location.href = url;
  }
}

export default FiLoanOriginationClient;
