<template>
  <bb-upload
    v-model="files"
    v-validate="'required|uploadedFilesAreValid'"
    name="income-upload"
    :allowed-types="allowedTypes"
    :file-count-limit="10"
    :size-limit="5"
    flavor="user"
    :allowed-types-label="$t('views.incomeProof.fileNotAllowed')"
    :choose-label="$t('views.incomeProof.chooseLabel')"
    :capture-label="$t('views.incomeProof.captureLabel')"
    :duplicate-file-label="$t('views.incomeProof.dublicateFileLabel')"
    :empty-description-label="$t('views.incomeProof.emptyDescriptionLabel')"
    :empty-file-label="$t('views.incomeProof.emptyFileLabel')"
    :size-limit-label="$t('views.incomeProof.sizeLimitLabel')"
    :upload-another-label="$t('views.incomeProof.uploadAnotherLabel')"
    class="nm-b-md-30"
    @change="$emit('update:files', $event)"
  >
    <template slot="browseLabel">
      <i18n
        path="views.incomeProof.browse.label"
        tag="div"
      >
        <span class="upload__highlight color-green">{{ $t('views.incomeProof.browse.action') }}</span>
      </i18n>
    </template>
  </bb-upload>
</template>

<script>
  import { BbUpload } from '@bigbank/interface-components';
  export default {
    name: 'upload-files',
    components: { BbUpload },
    props: {
      coApplicant: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        files: [],
        allowedTypes: [
          'image/png',
          'image/jpg',
          'image/jpeg',
          'application/pdf',
        ],
      };
    },
  };
</script>
