<template>
  <div>
    <bb-hero
      :title="$t(`views.${definitionKey}.title`, [firstName])"
      :title-line-two="$t(`views.${definitionKey}.subtitle`)"
      data-test="heroTitle"
    />
    <bb-card>
      <h4
        class="f-strong smallest f-color-gray-90 f-align-center"
        data-test="description1"
      >
        {{ $t(`views.${definitionKey}.description1`) }}
      </h4>
      <i18n
        :path="`views.${definitionKey}.description2`"
        class="smallest f-color-mint f-align-center"
        tag="p"
        data-test="description2"
      >
        <bb-link
          :to="'tel:' + $t('footer.phone')"
          :label="$t('footer.phone')"
          no-underline
          class="bold"
        />
      </i18n>
    </bb-card>
    <bb-container
      class="m-t-35"
    >
      <bb-row
        class="row justify-content-center"
      >
        <bb-col
          class="col-sm-20 col-md-14 col-lg-10 col-xl-8"
        >
          <bb-button
            display="block"
            mint
            :loading="isLoading"
            class="h-100 p-x-20"
            @click="logout"
          >
            {{ $t(`views.${definitionKey}.backToSelfService`) }}
          </bb-button>
        </bb-col>
      </bb-row>
    </bb-container>
  </div>
</template>

<script>
  import { BbHero,
           BbCard,
           BbLink,
           BbButton,
           BbContainer,
           BbRow,
           BbCol } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'result-liabilities',
    components: {
      BbHero,
      BbCard,
      BbLink,
      BbButton,
      BbContainer,
      BbRow,
      BbCol,
    },
    props: {
      definitionKey: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'firstName',
      ]),
    },
    methods: { ...mapActions('auth', ['logout']) },
  };
</script>
