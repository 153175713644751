<template>
  <bb-col
    class="p-20"
    style="border: 8px dashed lightgrey"
  >
    <locale-changer />

    <bb-radio
      label="Route"
      grouped
      :options="routes"
      name="route-changer"
      @change="changeRoute"
    />
  </bb-col>
</template>

<script>
  import { BbCol, BbRadio } from '@bigbank/interface-components';
  import LocaleChanger from './LocaleChanger';
  import { processStep } from '@bigbank/web-loco';
  import { mockProcessSteps } from '../../tests/mocks/process-mock';

  export default {
    name: 'development-helper',
    components: {
      LocaleChanger,
      BbCol,
      BbRadio,
    },
    computed: {
      routes() {
        return this.$router.options.routes.map(route => ({
          text: route.path,
          value: route.name,
        }));
      },
    },
    created() {
      // eslint-disable-next-line no-console
      console.info('created', this.$route.name);
      // Set up default data when app created or HMR
      this.setupRoute(this.$route.name);
    },
    methods: {
      async changeRoute(routeName) {
        await this.setupRoute(routeName);
        this.$router.push({ name: routeName });
      },
      async setupRoute(routeName) {
        // Default data and appearance for each route
        // eslint-disable-next-line no-console
        // Not every route has a mockProcessSteps step
        console.info('Setting up default data and appearance for route/task', routeName);

        const newApplication = { task: { definitionKey: '' }};

        if (mockProcessSteps[routeName]) {
          if (routeName === 'chooseProduct') {
            await this.$store.dispatch('products/getProducts', {}, { root: true });
          } else if (routeName === 'newApplication') {
            this.$store.commit(`processStep/${processStep.types.PROCESS_STEP_UPDATED}`, newApplication);
          } else {
            // error pages
          }
          this.$store.commit(`processStep/${processStep.types.PROCESS_STEP_UPDATED}`, mockProcessSteps[routeName]);
        }
        this.$store.dispatch('ui/updateUi', routeName, { root: true });
      },
    },
  };
</script>
