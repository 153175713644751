<template>
  <bb-offer-thank-you-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.submit'),
      disabled: !isSubmitEnabled,
      blockSubmit: !isSubmitEnabled,
      loading: isLoading,
      submitEditIcon: true,
      submitMetaInfo: submitMetaInfo
    }"
    class="refinancing-offer"
    @edit="scrollToCalculator"
    @submit="submit"
  >
    <template slot="header">
      <steps-overview />
    </template>

    <liabilities-details-refinancing
      :data="liabilities"
      :applicant-bank-account-number="bankAccountNumber"
      @update="updateLiabilities"
    />

    <template
      slot="form-extras"
    >
      <liabilities-details-refinancing
        v-if="hasCoApplicant"
        :data="coApplicantLiabilities"
        :applicant-bank-account-number="bankAccountNumber"
        is-co-applicant
        @update="updateLiabilities"
      />
      <liabilities-details-calculator
        :offer="offer"
        :payout-consent="payoutConsent"
        :refinancing-amount="refinancingAmount"
        @update="updateCalculator"
      />
      <bb-card
        size="fluid"
        class="card-payment"
      >
        <bb-labeled-value
          :value="$n(refinancingAmount.remaining, 'currency')"
          :label="$t('components.refinancing.remainingAmount')"
          :data-vv-as="$t('components.refinancing.remainingAmount')"
          label-uppercase
          :color="refinancingAmount.remaining === 0 ? 'gray-40' : 'gray'"
          name="remaining-amount"
        />
        <bb-checkbox
          :value="payoutConsent"
          :label="$t('application.transferRemainingAmountConsent')"
          :data-vv-as="$t('application.transferRemainingAmountConsent')"
          name="payout-consent"
          data-test="payoutConsent"
          @input="payoutConsent = !payoutConsent"
        >
          <bb-input
            slot="conditional"
            v-model="bankAccountNumber"
            v-validate.immediate="{ required: payoutConsent, iban: 'iban', ibanShouldNotEqual: liabilitiesAccountNumbers }"
            :label="$t('application.remainingAmountAccountNumber')"
            :data-vv-as="$t('application.remainingAmountAccountNumber')"
            name="payout-account-number"
            data-test="bankAccountNumber"
          />
        </bb-checkbox>
        <bb-checkbox
          :value="dataConsent"
          :label="$t('application.dataConsent')"
          name="data-consent"
          data-test="dataConsent"
          @input="dataConsent = !dataConsent"
        />
      </bb-card>
    </template>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbOfferThankYouPage,
           BbCard,
           BbLabeledValue,
           BbInput,
           BbCheckbox,
           jump } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import { nonRefinanceableTypes } from '@/constants';
  import StepsOverview from '@/components/StepsOverview';
  import LiabilitiesDetailsCalculator from '@/components/LiabilitiesDetailsCalculator';
  import LiabilitiesDetailsRefinancing from '@/components/LiabilitiesDetailsRefinancing.vue';

  export default {
    name: 'liabilities-details',
    components: {
      BbOfferThankYouPage,
      StepsOverview,
      LiabilitiesDetailsCalculator,
      LiabilitiesDetailsRefinancing,
      BbCard,
      BbLabeledValue,
      BbInput,
      BbCheckbox,
    },
    data() {
      return {
        calculatorData: {},
        dataConsent: false,
        payoutConsent: false,
        bankAccountNumber: '',
        liabilities: [],
        coApplicantLiabilities: [],
        isCalculatorAlert: false,
        isFormValid: false,
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'offer',
        'isLoading',
        'hasCoApplicant',
        'refinancingData',
        'paymentData',
      ]),
      nonRefinanceableTypes() {
        return nonRefinanceableTypes;
      },
      applicationLiabilities() {
        return this.liabilities.concat(this.coApplicantLiabilities);
      },
      loanAmount() {
        const { used } = this.refinancingAmount;
        const { amount } = this.calculatorData;

        return used ? amount + this.offer.conclusionFee : amount;
      },
      submitMetaInfo() {
        return [
          this.$n(this.loanAmount, 'currency'),
          [
            this.$n(this.calculatorData.monthlyPayment, 'currency'),
            this.$t('components.refinancing.month').toLowerCase(),
          ].join('/'),
        ];
      },
      isSubmitEnabled() {
        this.validateForm();
        const { remaining, used } = this.refinancingAmount;
        const {
          calculatorData: { amount, period },
          isCalculatorAlert,
          applicationLiabilities,
          hasCoApplicant,
          bankAccountNumber,
          payoutConsent,
          dataConsent,
          nonRefinanceableTypes,
          isLoading,
          isFormValid,
        } = this;

        if (isLoading || !isFormValid || !amount || !period) return false;

        if (used === 0 || used > amount) return false;

        if ((payoutConsent && !bankAccountNumber) || !dataConsent) return false;

        if ((!payoutConsent && used < amount) || isCalculatorAlert) return false;

        const errors = applicationLiabilities.reduce((a, { isToggled, type, creditor, bankAccountNumber, referenceNumber, paymentDescription, declaredAmount, declaredMonthlyPayment }) => {
          if (!type || !declaredAmount || !declaredMonthlyPayment) a++;
          if (!nonRefinanceableTypes.includes(type)) {
            if (!declaredAmount || !declaredMonthlyPayment) a++;
            if (isToggled && ((!referenceNumber && !paymentDescription) || !creditor || !bankAccountNumber)) a++;
          }

          return a;
        }, 0);

        return !errors;
      },
      refinancingAmount() {
        const { applicationLiabilities, payoutConsent, nonRefinanceableTypes } = this;
        const amount = this.calculatorData.amount;
        const used = applicationLiabilities.reduce((a, { isToggled, type, declaredAmount }) => {
          if (isToggled && !nonRefinanceableTypes.includes(type)) return a + (declaredAmount ?? 0);

          return a;
        }, 0);

        const diff = (amount - used).toFixed(2);
        const remaining = diff <= 0.00 ? 0 : diff;

        return {
          used,
          remaining,
        };
      },
      liabilitiesAccountNumbers() {
        return this.applicationLiabilities.map(o => o.bankAccountNumber);
      },
    },
    created() {
      const { liabilities, coApplicantLiabilities } = this.refinancingData;
      this.liabilities = this.convertLiabilities(liabilities, true);
      this.coApplicantLiabilities = this.convertLiabilities(coApplicantLiabilities, true);
      this.bankAccountNumber = this.paymentData.bankAccountNumber ?? '';
    },
    methods: {
      ...mapActions('processStep', ['updateProcessStep']),
      updateCalculator({ amount, period, monthlyPayment, isAlert }) {
        this.calculatorData = {
          amount,
          period,
          monthlyPayment,
        };
        this.isCalculatorAlert = isAlert;
      },
      updateLiabilities({ source, data }) {
        this[source] = data;
      },
      async validateForm() {
        this.isFormValid = await this.$validator.validateAll();
      },
      //convert liabilities for ui and backend
      convertLiabilities(data, ui = false) {
        return data.map((object) => {
          const { refinancing = {}, type, isToggled = false, hideToggle = false, ...liabilityModel } = object;
          if (ui) {
            const { bankAccountNumber, paymentDescription, referenceNumber, selectedForRefinanceByCustomer = false } = refinancing;
            const hideToggle = this.nonRefinanceableTypes.includes(type);

            return {
              ...liabilityModel,
              type,
              isToggled: selectedForRefinanceByCustomer,
              hideToggle,
              bankAccountNumber,
              paymentDescription,
              referenceNumber,
            };
          } else {
            const { bankAccountNumber, paymentDescription = undefined, referenceNumber = undefined, ...model } = liabilityModel;

            return {
              ...model,
              type,
              refinancing: {
                selectedForRefinanceByCustomer: isToggled,
                isRefinanced: isToggled,
                bankAccountNumber,
                paymentDescription,
                referenceNumber,
              },
            };
          }
        });
      },
      submit() {
        if (!this.isSubmitEnabled) return;
        const { loanAmount, hasCoApplicant, liabilities, coApplicantLiabilities, payoutConsent, bankAccountNumber } = this;

        let application = {
          applicant: { liabilities: this.convertLiabilities(liabilities) },
          offerChange: {
            loanAmount,
            loanMaturity: this.calculatorData.period,
          },
        };
        if (hasCoApplicant && coApplicantLiabilities.length) {
          application = {
            ...application,
            coApplicant: { liabilities: this.convertLiabilities(coApplicantLiabilities) },
          };
        }
        if (payoutConsent && bankAccountNumber) {
          application.applicant = {
            ...application.applicant,
            bankAccountNumber,
          };
        }

        this.updateProcessStep({ application });
      },
      scrollToCalculator() {
        jump('.card-calculator', {
          offset: -40,
          duration: 400,
        });
      },
    },
  };
</script>

<style lang="scss">
  .refinancing-offer {
    .bb-card {
      max-width: 100%;
      margin-bottom: 20px;
    }
    .bb-card:last-child {
      margin-bottom: 0;
    }
    .bb-offer-thank-you-page__form-extras {
      max-width: 100%;
      margin: 1rem auto 0;
    }
    .bb-input.disabled {
      label {
        opacity: .5;
      }
    }
    .calculator-monthly-payment-sticky:after {
      content: '/';
      padding: 0 5px;
    }
  }
</style>
