class TrackingData {

  getParameter (param) {
    return Array.isArray(param) && param.length > 0 ? param[param.length - 1] : param;
  };

  getData () {
    const data = { userAgent: this.getUserAgent() };

    if (this.getTrackingId() && this.isGoogleAnalyticsEnabled()) {
      data.googleAnalyticsCustomerId = this.getGaClientId();
      data.screenResolution = this.getGaScreenResolution();
      data.viewportSize = this.getGaViewportSize();
    }

    return data;
  }

  getTrackingId() {
    return window.config ? window.config.GA_TRACKING_ID : null;
  }

  getGaClientId () {
    return this.getProperty('clientId') ?? window.document.cookie.match(/_ga=(.+?);/)?.[1].split('.').slice(-2).join('.');
  }

  getGaScreenResolution () {
    return this.getProperty('screenResolution');
  }

  getGaViewportSize () {
    return this.getProperty('viewportSize');
  }

  getUserAgent () {
    return navigator.userAgent;
  }

  getProperty (property) {
    const trackers = window.ga.getAll();
    let i, len;

    for (i = 0, len = trackers.length; i < len; i += 1) {
      if (trackers[i].get('trackingId') === this.getTrackingId()) {
        return trackers[i].get(property);
      }
    }
  }

  isGoogleAnalyticsEnabled () {
    return !!(window.ga && typeof window.ga.getAll === 'function');
  }

  getCampaignData (params) {
    return {
      campaign: this.getParameter(params.bbcampaign),
      channel: this.getParameter(params.bbchannel),
      keyword: this.getParameter(params.bbkeyword),
      medium: this.getParameter(params.bbmedium),
      source: this.getParameter(params.bbsource),
    };
  };
}

export default new TrackingData();
