// Public application
const newApplication = {
  application: {
    agreeToTerms: true,
    source: { channel: '' },
    marketingConsent: false,
    applicant: {
      firstName: '',
      surname: '',
      personalIdentityCode: '',
      email: '',
      phone: '',
    },
    loanPurpose: '',
    calculatorData: { productType: '' },
  },
  task: { definitionKey: '' },
};

export const submitAdditionalPersonalData = {
  application: {
    applicant: {
      maritalStatus: '',
      residenceType: '',
      employment: {
        fieldOfActivity: '',
        workStartDate: '',
        companyName: '',
        companyStartDate: '',
        companyRegistrationCode: '',
      },
      education: '',
      nonPolitical: undefined,
    },
    hasCoApplicant: '',
  },
  task: { definitionKey: 'submitAdditionalPersonalData' },
};

export const submitAdditionalPersonalDataCoApplicant = {
  application: {
    applicant: { personalIdentityCode: '131052-308T' },
    coApplicant: {
      firstName: '',
      surname: '',
      personalIdentityCode: '',
      email: '',
      phone: '',
      employment: {
        fieldOfActivity: '',
        workStartDate: '',
        companyName: '',
        companyStartDate: '',
        companyRegistrationCode: '',
      },
      education: '',
      nonPolitical: undefined,
    },
    offer: { decision: '' },
    hasCoApplicant: '',
  },
  task: { definitionKey: 'submitAdditionalPersonalDataCoApplicant' },
};

export const submitIncomesAndObligationsCoApplicant = {
  application: {
    coApplicant: {
      incomes: [],
      liabilities: [],
      allowRegistryCheck: true,
      agreeToPersonalDataProcessing: true,
    },
  },
  task: { definitionKey: 'submitIncomesAndObligationsCoApplicant' },
};

// Scenario B
const chooseProduct = {
  application: {
    offerChange: {
      productType: 'LOANFI09',
      loanMaturity: '72',
      loanAmount: 5000,
    },
  },
  task: {
    definitionKey: 'chooseProduct',
    name: 'Choose product',
  },
};

const personalDetails = {
  application: {
    agreeToTerms: true,
    applicant: {
      firstName: 'Mikko',
      surname: 'Kuusisto',
      personalIdentityCode: '270655-747J',
      email: 'mikko@kuusisto.com',
      phone: '+358942599691', // Bigbank FI number
    },
    loanPurpose: 'OTHER',
    calculatorData: {
      loanAmount: 4567,
      loanPeriod: 62,
      productType: 'LOANFI09',
    },
  },
  task: { definitionKey: 'personalDetails' },
};

export const backgroundInformation = {
  application: {
    applicant: {
      maritalStatus: 'MARRIED',
      residenceType: 'RIGHT_OF_RESIDENCE',
      employment: {
        fieldOfActivity: 'PRIVATE_SECTOR',
        workStartDate: '2010-10',
        companyName: 'Bigbank',
        companyStartDate: '2015-05',
        companyRegistrationCode: '1234567-8',
        employmentContractType: 'FIXED',
      },
      education: 'BASIC',
      nonPolitical: false,
    },
    calculatorData: {
      loanAmount: 4567,
      loanPeriod: 62,
      productType: 'LOANFI09',
    },
  },
  task: { definitionKey: 'backgroundInformation' },
};

export const financialDetails = {
  application: {
    applicant: {
      incomes: [],
      liabilities: [],
      allowRegistryCheck: true,
      agreeToPersonalDataProcessing: true,
    },
    calculatorData: {
      loanAmount: 4567,
      loanPeriod: 62,
      productType: 'LOANFI09',
    },
  },
  task: { definitionKey: 'financialDetails' },
};

// Common scenario
const acceptOffer = {
  application: {
    externalId: 'external-id',
    applicant: { firstName: 'Pekka' },
    hasCoApplicant: true,
    marketingConsent: false,
    offer: {
      aprc: 23.34,
      administrationFee: 123,
      conclusionFee: 2223,
      offeredMonthlyPayment: 344.45,
      offeredAmount: 2000,
      offeredPeriod: 60,
      interestRate: 11.12,
      customerMinPeriod: 36,
      customerMaxPeriod: 60,
      customerMaxMonthlyPayment: 1900,
      customerMinAmount: 1000,
      customerMaxAmount: 3000,
      decision: 'Approved',
      offeredProductType: 'LOANFI13',
    },
  },
  task: { definitionKey: 'acceptOffer' },
};

export const enterLiabilitiesDetails = {
  application: {
    externalId: 'external-id',
    applicant: {
      firstName: 'Pekka',
      bankAccountNumber: 'FI2112345600000785',
      liabilities: [
        {
          type: 'CAR_LOAN',
          creditor: 'Euro24 Finance Oy',
          declaredAmount: 7000,
          declaredMonthlyPayment: 20,
          refinancing: {
            isRefinanced: false,
            selectedForRefinanceByCustomer: false,
            bankAccountNumber: 'FI7412345600000025',
            referenceNumber: '123',
            paymentDescription: undefined,
          },
        },
        {
          type: 'CONSUMER_LOAN',
          creditor: 'Instabank ASA',
          declaredAmount: 200,
          declaredMonthlyPayment: 20,
          refinancing: {
            isRefinanced: false,
            selectedForRefinanceByCustomer: false,
            bankAccountNumber: 'FI7412345600000025',
            referenceNumber: '123',
            paymentDescription: undefined,
          },
        },
        {
          type: 'CREDIT_CARD',
          creditor: 'Sav-Rahoitus Oyj',
          declaredAmount: 300,
          declaredMonthlyPayment: 20,
          refinancing: {
            isRefinanced: false,
            selectedForRefinanceByCustomer: false,
            bankAccountNumber: 'FI7412345600000025',
            referenceNumber: '123',
            paymentDescription: undefined,
          },
        },
      ],
    },
    hasCoApplicant: false,
    offer: {
      aprc: 23.34,
      administrationFee: 123,
      conclusionFee: 50,
      offeredMonthlyPayment: 344.45,
      offeredAmount: 5000,
      offeredPeriod: 60,
      interestRate: 11.12,
      customerMinPeriod: 36,
      customerMaxPeriod: 60,
      customerMaxMonthlyPayment: 1900,
      customerMinAmount: 3000,
      customerMaxAmount: 25000,
      decision: 'RejectedRefinance',
      offeredProductType: 'LOANFI13',
    },
  },
  task: { definitionKey: 'enterLiabilitiesDetails' },
};

export const enterLiabilitiesDetailsCoApplicant = {
  application: {
    externalId: 'external-id',
    applicant: enterLiabilitiesDetails.application.applicant,
    coApplicant: {
      liabilities: [
        {
          type: 'CONSUMER_LOAN',
          creditor: 'Instabank ASA',
          declaredAmount: 1000,
          declaredMonthlyPayment: 20,
          refinancing: {
            isRefinanced: false,
            selectedForRefinanceByCustomer: false,
            bankAccountNumber: 'FI7412345600000025',
            referenceNumber: '',
            paymentDescription: 'payment',
          },
        },
        {
          type: 'CREDIT_CARD',
          creditor: 'Sav-Rahoitus Oyj',
          declaredAmount: 3000,
          declaredMonthlyPayment: 20,
          refinancing: {
            isRefinanced: false,
            selectedForRefinanceByCustomer: false,
            bankAccountNumber: 'FI7412345600000025',
            referenceNumber: '',
            paymentDescription: '',
          },
        },
      ],
    },
    hasCoApplicant: true,
    offer: {
      aprc: 23.34,
      administrationFee: 123,
      conclusionFee: 50,
      offeredMonthlyPayment: 344.45,
      offeredAmount: 5000,
      offeredPeriod: 60,
      interestRate: 11.12,
      customerMinPeriod: 36,
      customerMaxPeriod: 60,
      customerMaxMonthlyPayment: 1900,
      customerMinAmount: 3000,
      customerMaxAmount: 50000,
      decision: 'RejectedRefinance',
      offeredProductType: 'LOANFI13',
    },
  },
  task: { definitionKey: 'enterLiabilitiesDetails' },
};

export const viewLiabilitiesEnteredThankYou = {
  application: {
    applicant: { firstName: 'Pinja' },
    hasCoApplicant: false,
  },
  task: { definitionKey: 'viewLiabilitiesEnteredThankYou' },
};

const submitAdditionalData = {
  application: {
    monthlyPaymentDay: 15,
    applicant: { bankAccountNumber: 'FI2112345600000785' },
  },
  task: { definitionKey: 'submitAdditionalData' },
};

const involveCoBorrowerOrUseRefinancing = {
  application: {
    hasCoApplicant: true,
    involveCoBorrower: true,
  },
  task: { definitionKey: 'involveCoBorrowerOrUseRefinancing' },
};

export const submitIncomesAndObligations = {
  application: {
    applicant: {
      incomes: [],
      liabilities: [],
      allowRegistryCheck: true,
      agreeToPersonalDataProcessing: true,
    },
  },
  task: { definitionKey: 'submitIncomesAndObligations' },
};

export const viewNegativeThankYou = {
  application: {
    applicant: { firstName: 'Pinja' },
    decisionReasons: [
      'group1',
      'group2',
      'group3',
      'group4',
      'group5',
      'group6',
      'addressAbroad',
      'customerMinAge',
      'existingLoanFirstPaymentNotDone',
      'customerReserveNegative',
      'netIncomeUnderMinIndividual',
      'activeNegativeDecision',
      'noRegisteredAddress',
      'customerUnemployed',
    ],
  },
  task: {
    definitionKey: 'viewNegativeThankYou',
    name: 'View negative thank you page',
  },
};

const uploadIncomeProof = {
  application: {
    offer: { decision: 'ApprovedAdditionalCheck' },
    hasCoApplicant: false,
  },
  task: {
    definitionKey: 'uploadIncomeProof',
    name: 'Upload income proof',
  },
};

const uploadIncomeProofSentByEmail = {
  application: {
    offer: { decision: 'ApprovedAdditionalCheck' },
    hasCoApplicant: false,
  },
  task: {
    definitionKey: 'uploadIncomeProofSentByEmail',
    name: 'Upload income proof',
  },
};

const uploadAdditionalDocs = {
  application: {
    offer: { decision: 'ApprovedAdditionalCheck' },
    hasCoApplicant: false,
  },
  task: {
    definitionKey: 'uploadAdditionalDocs',
    name: 'Upload income proof',
  },
};

// Signicat signing
const beginSigning = {
  application: { externalId: 'external-id' },
  task: {
    definitionKey: 'beginSigning',
    name: 'Begin Signing',
  },
};

const applicantSigning = {
  application: { externalId: 'external-id' },
  task: {
    definitionKey: 'applicantSigning',
    name: 'Applicant Signing',
  },
};

const coApplicantSigning = {
  application: { externalId: 'external-id' },
  task: {
    definitionKey: 'coApplicantSigning',
    name: 'CoApplicant Signing',
  },
};

export const mockProcessSteps = {
  newApplication,
  submitAdditionalPersonalData,
  chooseProduct,
  personalDetails,
  backgroundInformation,
  financialDetails,
  submitIncomesAndObligations,
  submitAdditionalPersonalDataCoApplicant,
  submitIncomesAndObligationsCoApplicant,
  acceptOffer,
  enterLiabilitiesDetails,
  enterLiabilitiesDetailsCoApplicant,
  submitAdditionalData,
  involveCoBorrowerOrUseRefinancing,
  viewLiabilitiesEnteredThankYou,
  viewNegativeThankYou,
  uploadIncomeProof,
  uploadIncomeProofSentByEmail,
  beginSigning,
  applicantSigning,
  coApplicantSigning,
  uploadAdditionalDocs,
};
