const numberFormats = {
  fi: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    'currency-no-decimal': {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
};

export default numberFormats;
