import Vue from 'vue';
import Vuex from 'vuex';
import { auth, LoanOriginationClient, sessionExpiration } from '@bigbank/web-loco';
import ui from './modules/ui';
import { createProcessStepModule } from './modules/processStep';
import { createProductsModule } from './modules/products';
import signing from './modules/signing';

Vue.use(Vuex);

// Now possible to set client by some config value
// Switch with dev client for mocking
// const client = LoanOriginationClientDev;
const client = LoanOriginationClient;

const store = new Vuex.Store({
  modules: {
    auth: auth.moduleBuilder(client),
    processStep: createProcessStepModule(client),
    products: createProductsModule(client),
    sessionExpiration: sessionExpiration.moduleBuilder(client),
    ui,
    signing,
  },
});

client.syncStore(store);

export default store;
