<template>
  <div
    id="app"
    class="app-container"
    :class="classes"
  >
    <app-header />
    <main>
      <!-- force component re-render when API request finishes -->
      <!-- can't rely on route change as a task might route back to same task with new data -->
      <router-view :key="$store.getters['processStep/lastUpdated']" />
      <session-expiration
        :button-label="$t('sessionExpiration.button')"
        :description="$t('sessionExpiration.description')"
        :title="$t('sessionExpiration.title')"
      />
      <development-helper v-if="config.ENABLE_DEV_HELPER" />
      <back-navigation-modal
        :description="$t('backButtonModal.description')"
        :positive-button-text="$t('backButtonModal.positiveButtonText')"
        :allow-routing-to-first-page="false"
      />
    </main>
    <app-footer />
  </div>
</template>

<script>
  import AppHeader from './components/AppHeader.vue';
  import AppFooter from './components/AppFooter.vue';
  import { SessionExpiration, BackNavigationModal } from '@bigbank/web-loco';
  import extend from './util/extendValidation';
  import { mapGetters, mapActions } from 'vuex';
  import DevelopmentHelper from './development/DevelopmentHelper';

  export default {
    components: {
      AppFooter,
      AppHeader,
      SessionExpiration,
      DevelopmentHelper,
      BackNavigationModal,
    },
    computed: {
      ...mapGetters('ui', ['backgroundFlavor']),
      classes() {
        return { [`bg-${this.backgroundFlavor}`]: this.backgroundFlavor };
      },
    },
    async created() {
      extend(this.$validator);
      await this.initializeSsoSession();
      await this.appStarted();
      await this.initializeHeartbeat();
    },
    methods: {
      ...mapActions('processStep', ['appStarted']),
      ...mapActions('auth', [
        'initializeSsoSession',
        'initializeHeartbeat',
      ]),
    },
  };
</script>

<style lang="scss">
  main {
    flex-grow: 1;
  }
  .app-container {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
  }
</style>
