<template>
  <bb-footer
    class="app-footer"
    condensed
    :disclaimer="copyright"
    :contact-links="contactLinks"
    :social-links="socialLinks"
  />
</template>

<script>
  import { BbFooter } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/social/facebook';
  import '@bigbank/interface-components/dist/svg/ui/email-outline';
  import '@bigbank/interface-components/dist/svg/ui/phone-outline';

  export default {
    name: 'app-footer',
    components: { BbFooter },
    data() {
      return {
        copyright: this.$t('footer.copyright'),
        contactLinks: [
          {
            label: this.$t('footer.callUs'),
            icon: 'ui-phone-outline',
            link: {
              label: this.$t('footer.phone'),
              to: `tel:0942599691`,
            },
          },
          {
            label: this.$t('footer.writeUs'),
            icon: 'ui-email-outline',
            link: {
              label: this.$t('footer.email'),
              to: `mailto:info@bigbank.fi`,
            },
          },
        ],
        socialLinks: [{
          icon: 'social-facebook',
          to: 'https://www.facebook.com/Bigbank-Suomi-419921091398675/',
        }],
      };
    },
  };
</script>
