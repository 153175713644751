<template>
  <div class="text-center bg-gray-10 p-30">
    <bb-error-page v-bind="content" />
  </div>
</template>

<script>
  import { BbErrorPage } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/illustration/error500';
  import '@bigbank/interface-components/dist/svg/illustration/error404';
  import '@bigbank/interface-components/dist/svg/illustration/maintenance';
  import '@bigbank/interface-components/dist/svg/illustration/no-account';
  import '@bigbank/interface-components/dist/svg/illustration/session-expired';

  export default {
    name: 'error-page',
    components: { BbErrorPage },
    props: {
      errorType: {
        type: String,
        required: true,
        validator(value) {
          // The value must match one of these strings
          return [
            'error500',
            'maintenance',
            'error404',
            'no-account',
            'session-expired',
            'unauthorized',
          ].indexOf(value) !== -1;
        },
      },
    },
    computed: {
      content() {
        return {
          icon: this.errorType === 'unauthorized' ? `illustration-no-account` : `illustration-${this.errorType}`,
          title: this.$t(`views.${this.errorType}.title`),
          description: this.$t(`views.${this.errorType}.description`),
          buttonText: this.$t(`views.${this.errorType}.buttonText`),
          // Hard-coding path here so client can navigate even if there is no connection to LOS
          buttonPath: 'https://verkkopankki.bigbank.fi',
        };
      },
    },
  };
</script>
