<template>
  <bb-offer-thank-you-page
    ref="offer"
    :form-props="{
      submitText: $t(`views.${definitionKey}.backToSelfService`)
    }"
    @submit="logout"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="heroTitle"
      />
    </template>
    <p
      id="negative-description"
      class="smallest"
      :class="{'m-b-10' : displayReasons, 'm-b-20' : !displayReasons}"
    >
      {{ $t(`views.${definitionKey}.description1`) }}
    </p>
    <div v-if="displayReasons">
      <bb-list-item
        v-for="(reason, index) in decisionReasons"
        :id="'negative-reason-' + index"
        :key="index"
        :title="$t(`views.${definitionKey}.rules.${reason}`)"
        :title-uppercase="false"
        bullet
        condensed
      />
    </div>
    <p
      id="negative-description-two"
      class="smallest m-t-10"
    >
      {{ $t(`views.${definitionKey}.description2`) }}
    </p>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbOfferThankYouPage, BbListItem, BbHero } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'negative-result',
    components: {
      BbOfferThankYouPage,
      BbListItem,
      BbHero,
    },
    props: {
      definitionKey: {
        type: String,
        required: true,
        validator(value) {
          return value === 'viewNegativeThankYou';
        },
      },
    },
    computed: {
      ...mapGetters('processStep', [
        'decisionReasons',
        'firstName',
      ]),
      displayReasons() {
        return this.decisionReasons ? this.decisionReasons.length !== 0 : false;
      },
      heroTitle() {
        return this.$t(`views.${this.definitionKey}.title`).replace('{0}', this.firstName);
      },
    },
    methods: { ...mapActions('auth', ['logout']) },
  };
</script>
