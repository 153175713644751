<template>
  <div class="m-y-20 loan-amount-over-limit-alert">
    <bb-icon
      name="ui-alert-circle-outline"
      fill="yellow"
      size="24"
      class="m-r-5"
    />
    <span class="vertical-align-middle f-smallest">
      {{ $t(alertText) }}
    </span>
  </div>
</template>

<script>
  import { BbIcon } from '@bigbank/interface-components';
  export default {
    name: 'loan-amount-over-limit-alert',
    components: { BbIcon },
    props: {
      alertText: {
        type: String,
        default: 'components.calculator.amountOverLimit',
      },
    },
  };
</script>

<style scoped>
.loan-amount-over-limit-alert {
    padding: .9375rem;
    border: 2px solid #dee3e8;
    border-radius: .625rem;
    background-color: #f7f7f8;
}
</style>
