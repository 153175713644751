<template>
  <div>
    <bb-select
      v-model="employment.fieldOfActivity"
      v-validate="{ required: allRequired }"
      :options="fieldOfActivityOptions"
      :label="$t('application.employment.fieldOfActivity.label')"
      :data-vv-as="$t('application.employment.fieldOfActivity.label')"
      name="field-of-activity"
      @input="onInput"
    />
    <template v-if="isPrivateSector">
      <bb-masked-input
        key="work-start-date"
        v-model="employment.workStartDate"
        v-validate="{ required: allRequired, monthYear: 'monthYear'}"
        :label="$t('application.employment.workStartDate.label')"
        :data-vv-as="$t('application.employment.workStartDate.label')"
        :mask="dateMask"
        name="work-start-date"
        @input="onInput"
      />
      <bb-select
        v-model="employment.employmentContractType"
        v-validate="{ required: allRequired }"
        :options="employmentContractTypeOptions"
        :label="$t('application.employment.employmentContractType.label')"
        :data-vv-as="$t('application.employment.employmentContractType.label')"
        name="employment-contract-type"
        @input="onInput"
      />
      <bb-input
        key="company-name"
        v-model="employment.companyName"
        v-validate="{ required: allRequired, companyName: 'companyName'}"
        :label="$t('application.employment.companyName.label')"
        :data-vv-as="$t('application.employment.companyName.label')"
        name="company-name"
        @input="onInput"
      />
    </template>
    <template v-if="isEntrepreneur">
      <bb-masked-input
        key="company-start-date"
        v-model="employment.companyStartDate"
        v-validate="{ required: allRequired, monthYear: 'monthYear'}"
        :label="$t('application.employment.companyStartDate.label')"
        :data-vv-as="$t('application.employment.companyStartDate.label')"
        :mask="dateMask"
        name="company-start-date"
        @input="onInput"
      />
      <bb-masked-input
        key="company-registration-code"
        v-model="employment.companyRegistrationCode"
        v-validate="{ required: allRequired, registrationCode: 'registrationCode'}"
        :label="$t('application.employment.companyRegistrationCode.label')"
        :data-vv-as="$t('application.employment.companyRegistrationCode.label')"
        name="company-registration-code"
        :mask="registrationCodeMask"
        @input="onInput"
      />
    </template>
    <div
      v-if="isUnemployed"
      class="p-t-10"
    >
      <bb-icon
        name="ui-alert-circle-outline"
        size="24"
        fill="yellow"
      />
      <span class="p-l-5 f-smallest">
        {{ $t('views.backgroundInformation.incomeWarning') }}
      </span>
    </div>
  </div>
</template>

<script>
  import { employmentContractTypeOptions, fieldOfActivityOptions } from '../constants';
  import enumsToOptions from '../mixins/enumsToOptions';
  import { BbSelect,
           BbInput,
           BbIcon,
           BbMaskedInput } from '@bigbank/interface-components';
  import { toDisplayDate } from '../util/dateFormatter';

  export default {
    name: 'field-of-activity',
    components: {
      BbInput,
      BbIcon,
      BbSelect,
      BbMaskedInput,
    },
    mixins: [enumsToOptions],
    inject: ['$validator'],
    props: {
      value: {
        type: Object,
        required: true,
      },
      allRequired: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        // getters unavailable at data init, so accessing state directly
        employment: {
          ...this.value,
          workStartDate: toDisplayDate(this.value.workStartDate),
          companyStartDate: toDisplayDate(this.value.companyStartDate),
        },
        dateMask: '11/1111',
        registrationCodeMask: '1111111-1',
      };
    },
    computed: {
      fieldOfActivityOptions() {
        return this.enumsToOptions(fieldOfActivityOptions, 'application.employment.fieldOfActivity');
      },
      employmentContractTypeOptions() {
        return this.enumsToOptions(employmentContractTypeOptions, 'application.employment.employmentContractType');
      },
      isPrivateSector() {
        return this.employment.fieldOfActivity === fieldOfActivityOptions.privateSector;
      },
      isEntrepreneur() {
        return this.employment.fieldOfActivity === fieldOfActivityOptions.entrepreneur;
      },
      isUnemployed() {
        return this.employment.fieldOfActivity === fieldOfActivityOptions.unemployed;
      },
    },
    methods: {
      onInput() {
        this.$emit('input', this.employment);
      },
    },
  };
</script>
