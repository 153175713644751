import GtmEvent from './GtmEvent';

const routesToSkip = [
  'start',
  'maintenance',
  'noTask',
  'error500',
  'error404',
];

export default function trackEvents(Vue, router, store) {
  router.afterEach(toRoute => {
    if (routesToSkip.includes(toRoute.name)) {
      return;
    }

    Vue.gtm.trackEvent(new GtmEvent({
      bbeventcategory: store.state.products.product.productType,
      bbeventlabel: `FI Web Loan application ${ toRoute.name } Pageview`,
      bbapplicationid: store.getters['processStep/externalId'],
    }).filterEmptyValues());

    Vue.gtm.trackEvent(new GtmEvent({
      event: 'pageview',
      bbeventcategory: store.state.products.product.productType,
      bbeventlabel: `FI Web Loan application ${ toRoute.name } Pageview`,
      bbapplicationid: store.getters['processStep/externalId'],
    }).filterEmptyValues());
  });
}
