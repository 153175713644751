export const loanPurposeOptions = {
  renovatingHome: 'RENOVATING_HOME',
  purchasingMotorVehicles: 'PURCHASING_MOTOR_VEHICLES',
  traveling: 'TRAVELING',
  purchasingHomeAppliancesElectronics: 'PURCHASING_HOME_APPLIANCES_ELECTRONICS',
  wedding: 'WEDDING',
  medicalServices: 'MEDICAL_SERVICES',
  dailySettlements: 'DAILY_SETTLEMENTS',
  combiningLoans: 'COMBINING_LOANS',
  otherPurpose: 'OTHER',
};

export const purposesOfProduct = {
  LOANFI09: [
    loanPurposeOptions.otherPurpose, // default
    loanPurposeOptions.combiningLoans,
    loanPurposeOptions.purchasingHomeAppliancesElectronics,
    loanPurposeOptions.medicalServices,
    loanPurposeOptions.wedding,
  ],
  LOANFI10: [loanPurposeOptions.purchasingMotorVehicles],
  LOANFI11: [
    loanPurposeOptions.dailySettlements,
    loanPurposeOptions.traveling,
  ],
  LOANFI12: [loanPurposeOptions.renovatingHome],
  REFINANCING_LOAN_FI01: [loanPurposeOptions.combiningLoans],
};

export const mapProductTypeFromQuery = {
  project: 'LOANFI09',
  car: 'LOANFI10',
  small: 'LOANFI11',
  renovation: 'LOANFI12',
  refin: 'REFINANCING_LOAN_FI01',
};

export const findProductTypeByLoanPurpose = (loanPurpose) =>
  Object.keys(purposesOfProduct).find(product =>
    purposesOfProduct[product].includes(loanPurpose),
  );

export const PRIVATE_SECTOR = 'PRIVATE_SECTOR';
export const ENTREPRENEUR = 'ENTREPRENEUR';
export const UNEMPLOYED = 'UNEMPLOYED';

export const fieldOfActivityOptions = {
  privateSector: 'PRIVATE_SECTOR',
  entrepreneur: 'ENTREPRENEUR',
  unemployed: 'UNEMPLOYED',
  student: 'STUDENT',
  pensioner: 'PENSIONER',
  individualActivity: 'INDIVIDUAL_ACTIVITY',
};

export const {
  privateSector,
  entrepreneur,
  ...nonConditionalActivityOptions
} = fieldOfActivityOptions;

export const maritalStatusOptions = {
  single: 'SINGLE',
  cohabiting: 'COHABITING',
  divorced: 'DIVORCED',
  widow: 'WIDOW',
  married: 'MARRIED',
};

export const residenceTypeOptions = {
  privateApartment: 'PRIVATE_APARTMENT',
  partOwnership: 'PART_OWNERSHIP',
  rentalPremises: 'RENTAL_PREMISES',
  rightOfResidency: 'RIGHT_OF_RESIDENCE',
  livingWithParents: 'LIVING_WITH_PARENTS',
  other: 'OTHER',
};

export const educationOptions = {
  basic: 'BASIC',
  secondary: 'SECONDARY',
  secondarySpecialised: 'SECONDARY_SPECIALISED',
  higherBa: 'HIGHER_BA',
  higherMa: 'HIGHER_MA',
  higherPhD: 'HIGHER_PHD',
};

export const employmentContractTypeOptions = {
  fixed: 'FIXED',
  temporary: 'TEMPORARY',
};

export const incomeTypeOptions = {
  salary: 'SALARY',
  pension: 'PENSION',
};

export const liabilityTypeOptions = {
  mortgage: 'MORTGAGE',
  carLoan: 'CAR_LOAN',
  consumerLoan: 'CONSUMER_LOAN',
  hirePurchase: 'HIRE_PURCHASE',
  shortTermLoan: 'SHORT_TERM_LOAN',
  creditCard: 'CREDIT_CARD',
  other: 'OTHER',
  noLiabilities: 'NO_LIABILITIES',
};

export const nonRefinanceableTypes = [
  'MORTGAGE',
  'OTHER',
];

export const {
  noLiabilities,
  ...liabilityTypeOptionsWithoutNoLiabilities
} = liabilityTypeOptions;

export const ORIGIN_WEB = 'WEB';
export const CHANNEL_WEBPAGE = 'WEBPAGE';
export const CHANNEL_SELF_SERVICE = 'SELF_SERVICE';
export const SOURCE_BIGBANK = 'BIGBANK';
export const NET_MIN_INCOME = 1200;
export const COUNTRY_CODE = 'FI';
