<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: true,
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="$t('views.backgroundInformation.title')"
      icon="client-details"
    />
    <template v-if="existingApplication">
      <p class="text-center strong m-b-20 color-gray-90 f-small f-gotham-medium">
        {{ $t('views.backgroundInformation.doubleCheck') }}
      </p>
      <bb-separator
        class="m-b-10"
        dashed
      />
    </template>
    <bb-radio
      v-model="backgroundInfoData.maritalStatus"
      v-validate="'required'"
      :selected="backgroundInfoData.maritalStatus"
      :options="maritalStatusOptions"
      :label="$t('application.maritalStatus.label')"
      :data-vv-as="$t('application.maritalStatus.label')"
      name="marital-status"
      class="p-t-20 w-100"
    />
    <bb-checkbox
      v-if="suggestCoApplicant"
      v-model="hasCoApplicant"
      background
      class="m-t-30"
      name="has-co-applicant"
      :data-vv-as="$t('application.coApplicant.label')"
    >
      {{ $t('application.coApplicant.text') }}
      <p class="m-t-10 f-strong f-smallest f-color-gray-80 color-gray">
        {{ $t('application.coApplicant.additionalText') }}
      </p>
    </bb-checkbox>
    <bb-radio
      v-model="backgroundInfoData.residenceType"
      v-validate="'required'"
      :selected="backgroundInfoData.residenceType"
      :options="residenceTypeOptions"
      :label="$t('application.residenceType.label')"
      :data-vv-as="$t('application.residenceType.label')"
      name="residence-type"
      class="p-t-20"
    />
    <field-of-activity v-model="backgroundInfoData.employment" />
    <bb-select
      v-model="backgroundInfoData.education"
      v-validate="'required'"
      :options="educationOptions"
      :label="$t('application.education.label')"
      :data-vv-as="$t('application.education.label')"
      name="education-status"
    />
    <bb-checkbox
      :value="nonPolitical"
      background
      class="m-t-30"
      name="non-political"
      :data-vv-as="$t('application.nonPolitical.label')"
      @input="nonPolitical = !nonPolitical"
    >
      {{ $t('application.nonPolitical.text') }}
    </bb-checkbox>
  </bb-application-page>
</template>
<script>
  import { BbApplicationPage,
           BbCheckbox,
           BbRadio,
           BbSelect,
           BbSeparator,
           BbPublicHeader } from '@bigbank/interface-components';
  import FieldOfActivity from '../components/FieldOfActivity';
  import { mapActions, mapGetters } from 'vuex';
  import { educationOptions,
           fieldOfActivityOptions,
           maritalStatusOptions,
           residenceTypeOptions } from '../constants';
  import enumsToOptions from '../mixins/enumsToOptions';
  import pick from 'lodash.pick';
  import { toApplicationDate } from '../util/dateFormatter';

  export default {
    name: 'background-information',
    components: {
      BbApplicationPage,
      BbRadio,
      BbSelect,
      BbCheckbox,
      BbSeparator,
      BbPublicHeader,
      FieldOfActivity,
    },
    mixins: [enumsToOptions],
    data() {
      return {
        // getters unavailable at data init, so accessing state directly
        backgroundInfoData: { ...this.$store.getters['processStep/backgroundData'] },
        inputVal: '',
        nonPolitical: this.$store.getters['processStep/nonPolitical'],
        hasCoApplicant: null,
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'backgroundData',
        'isLoading',
        'definitionKey',
      ]),
      suggestCoApplicant() {
        const notLivingAloneStatuses = [
          maritalStatusOptions.married,
          maritalStatusOptions.cohabiting,
        ];

        return !this.existingApplication &&
          notLivingAloneStatuses.includes(this.backgroundInfoData.maritalStatus);
      },
      maritalStatusOptions() {
        return this.enumsToOptions(maritalStatusOptions, 'application.maritalStatus');
      },
      residenceTypeOptions() {
        return this.enumsToOptions(residenceTypeOptions, 'application.residenceType');
      },
      fieldOfActivityOptions() {
        return this.enumsToOptions(fieldOfActivityOptions, 'application.employment.fieldOfActivity');
      },
      educationOptions() {
        return this.enumsToOptions(educationOptions, 'application.education');
      },
      existingApplication() {
        return this.definitionKey === 'backgroundInformation';
      },
    },
    methods: {
      ...mapActions('processStep', ['updateProcessWithCalculatorData']),
      submit() {
        const employmentPicker = {
          [fieldOfActivityOptions.entrepreneur]: [
            'companyRegistrationCode',
            'companyStartDate',
          ],
          [fieldOfActivityOptions.privateSector]: [
            'workStartDate',
            'companyName',
            'employmentContractType',
          ],
          [fieldOfActivityOptions.pensioner]: [],
          [fieldOfActivityOptions.individualActivity]: [],
          [fieldOfActivityOptions.student]: [],
          [fieldOfActivityOptions.unemployed]: [],
        };

        const desiredProperties = [
          'fieldOfActivity',
          ...employmentPicker[this.backgroundInfoData.employment.fieldOfActivity],
        ];

        const employment = pick(this.backgroundInfoData.employment, desiredProperties);

        Object.entries(employment).forEach(([
          key,
          value,
        ]) => {
          if (key === 'workStartDate' || key === 'companyStartDate') {
            // Temporary hack to make Upsale work.
            if (!value.includes('-')) {
              employment[key] = toApplicationDate(value);
            }
          }
        });

        const application = {
          applicant: {
            maritalStatus: this.backgroundInfoData.maritalStatus,
            residenceType: this.backgroundInfoData.residenceType,
            education: this.backgroundInfoData.education,
            nonPolitical: !!this.nonPolitical,
            employment,
          },
        };

        if (!this.existingApplication) {
          application.hasCoApplicant = !!this.hasCoApplicant;
        }

        this.updateProcessWithCalculatorData({ application });
      },
    },
  };
</script>d
