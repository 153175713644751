<template>
  <bb-radio
    v-model="$i18n.locale"
    equal-width
    label="Locale change"
    :options="langs"
    class="w-100"
    name="locale-changer"
  />
</template>

<script>
  import { BbRadio } from '@bigbank/interface-components';

  export default {
    name: 'locale-changer',
    components: { BbRadio },
    data() {
      return {
        langs: [
          {
            text: 'fi',
            value: 'fi',
          },
          {
            text: 'en',
            value: 'en',
          },
        ],
      };
    },
  };
</script>
