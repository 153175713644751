<template>
  <component
    :is="isCoApplicant ? 'bb-card' : 'div'"
    size="fluid"
    :class="'card-liabilities' + (isCoApplicant ? '-coapplicant' : '')"
  >
    <div class="bb-card__title">
      {{ isCoApplicant
        ? $t('components.refinancing.coApplicantLiabilitiesText')
        : $t('components.refinancing.liabilitiesText')
      }}
    </div>
    <bb-form-repeater-toggleable
      v-model="liabilities"
      :not-required="isCoApplicant"
      :add-button-label="isCoApplicant
        ? $t('components.formRepeater.addTextCoApplicantObligations.label')
        : $t('components.formRepeater.addTextObligations.label')"
      :open-button-label="$t('components.formRepeater.buttons.checkDetails')"
      :close-button-label="$t('components.formRepeater.buttons.closeDetails')"
      :remove-button-label="$t('components.formRepeater.buttons.remove')"
      hide-initial-row
      class="m-t-20"
    >
      <template
        slot="header"
        slot-scope="row"
      >
        <bb-labeled-value
          :label="$t('components.formRepeater.labelObligationType')"
          :value="getObligationText(row)"
          size="14"
          extra-leading="7"
        />
        <bb-labeled-value
          :label="$t('components.formRepeater.labelOutstandingAmount')"
          :value="$n(row.model.declaredAmount, 'currency')"
          size="14"
          extra-leading="7"
        />
        <bb-tooltip
          v-if="row.model.hideToggle"
          class="align-self-center p-x-5"
          data-test="infoNonRefinanceable"
        >
          {{ $t('components.formRepeater.helpTextNonRefinanceable.label') }}
        </bb-tooltip>
      </template>
      <template
        slot="fields"
        slot-scope="row"
      >
        <bb-select
          v-model="row.model.type"
          v-validate="'required'"
          :options="liabilityTypeOptions"
          :label="$t('application.obligationType.label')"
          :data-vv-as="$t('application.obligationType.label')"
          :name="'liability-type_' + row.id"
          data-test="liabilityType"
          @change="$event => nonRefinanceableTypes.includes($event)
            ? ( row.model.isToggled = false, row.model.hideToggle = true )
            : ( row.model.isToggled = true, row.model.hideToggle = false )"
        />
        <template v-if="!nonRefinanceableTypes.includes(row.model.type)">
          <bb-typeahead
            v-validate.immediate="{ required: row.model.isToggled }"
            close-on-select
            :options="creditorsOptions"
            :label="$t('application.obligationForm.creditorName.label')"
            :data-vv-as="$t('application.obligationForm.creditorName.label')"
            label-prop-name="creditor"
            :name="'creditor-name_' + row.id"
            :value="row.model.creditor"
            data-test="creditorName"
            @change="row.model.creditor = $event.creditor"
          />
          <bb-input
            v-model="row.model.bankAccountNumber"
            v-validate.immediate="{ required: row.model.isToggled, ...validateIBAN(row.model.creditor), ibanShouldNotEqual: applicantBankAccountNumber }"
            type="string"
            :label="$t('application.obligationForm.bankAccountNumber.label')"
            :data-vv-as="$t('application.obligationForm.bankAccountNumber.label')"
            :name="'creditor-account-number_' + row.id"
            data-test="creditorAccountNumber"
            :formatter="ibanInput"
          />
          <bb-input
            v-model="row.model.referenceNumber"
            v-validate.immediate="{ required: row.model.isToggled && ((!row.model.referenceNumber && !row.model.paymentDescription) || !row.model.paymentDescription) }"
            :label="$t('application.obligationForm.referenceNumber.label')"
            :data-vv-as="$t('application.obligationForm.referenceNumber.label')"
            :name="'reference-number_' + row.id"
            data-test="creditorReferenceNumber"
            :help-active="true"
            :help-text="$t('application.obligationForm.referenceNumber.helpText')"
            :class="{ disabled: !!row.model.paymentDescription }"
            :disabled="row.model.paymentDescription ? true : false"
            :formatter="referenceNumberInput"
          />
          <bb-input
            v-model="row.model.paymentDescription"
            v-validate="{ required: row.model.isToggled && !row.model.referenceNumber }"
            :label="$t('application.obligationForm.paymentDescription.label')"
            :data-vv-as="$t('application.obligationForm.paymentDescription.label')"
            :name="'payment-description_' + row.id"
            data-test="paymentDescription"
            :class="{ disabled: !!row.model.referenceNumber }"
            :disabled="row.model.referenceNumber ? true : false"
            :formatter="paymentDescriptionInput"
          />
        </template>
        <bb-input
          v-validate="'required'"
          :value="row.model.declaredAmount ? row.model.declaredAmount : 0"
          currency
          add-on="EUR"
          :label="$t('application.obligationForm.outstandingAmount.label')"
          :data-vv-as="$t('application.obligationForm.outstandingAmount.label')"
          :name="'outstanding-amount_' + row.id"
          data-test="outstandingAmount"
          @input="row.model.declaredAmount = $event"
        />
        <bb-input
          v-validate="'required'"
          :value="row.model.declaredMonthlyPayment ? row.model.declaredMonthlyPayment : 0"
          currency
          add-on="EUR"
          :label="$t('application.obligationForm.monthlyPaymentAmount.label')"
          :data-vv-as="$t('application.obligationForm.monthlyPaymentAmount.label')"
          :name="'monthly-payment-amount_' + row.id"
          data-test="monthlyPaymentAmount"
          @input="row.model.declaredMonthlyPayment = $event"
        />
      </template>
    </bb-form-repeater-toggleable>
  </component>
</template>

<script>
  import { BbCard,
           BbFormRepeaterToggleable,
           BbTooltip,
           BbLabeledValue,
           BbTypeahead,
           BbSelect,
           BbInput } from '@bigbank/interface-components';
  import { liabilityTypeOptionsWithoutNoLiabilities,
           nonRefinanceableTypes } from '@/constants';
  import { ibanInput, referenceNumberInput, paymentDescriptionInput } from '@/util/helpers';
  import enumsToOptions from '@/mixins/enumsToOptions';

  import creditors from '@/creditors';

  export default {
    name: 'liabilities-details-refinancing',
    components: {
      BbCard,
      BbFormRepeaterToggleable,
      BbTooltip,
      BbLabeledValue,
      BbTypeahead,
      BbSelect,
      BbInput,
    },
    mixins: [enumsToOptions],
    props: {
      data: Array,
      isCoApplicant: Boolean,
      applicantBankAccountNumber: String,
    },
    data() {
      return { liabilities: '' };
    },
    computed: {
      liabilityTypeOptions() {
        return this.enumsToOptions(
          liabilityTypeOptionsWithoutNoLiabilities,
          'application.obligationType',
        );
      },
      nonRefinanceableTypes() {
        return nonRefinanceableTypes;
      },
      creditorsOptions() {
        return creditors.sort((a, b) => a.creditor.localeCompare(b.creditor));
      },
    },
    watch: {
      liabilities: {
        handler(data) {
          this.updateData(data);
        },
        deep: true,
      },
    },
    created() {
      this.liabilities = this.data;
    },
    methods: {
      ibanInput,
      referenceNumberInput,
      paymentDescriptionInput,
      updateData(data) {
        const source = !this.isCoApplicant ? 'liabilities' : 'coApplicantLiabilities';
        this.$emit('update', {
          source,
          data,
        });
      },
      getObligationText(row) {
        const selectedOption = this.liabilityTypeOptions.find(({ value }) =>
          value === row.model.type,
        );

        return selectedOption?.text;
      },
      validateIBAN(creditorName) {
        const creditor = this.creditorsOptions.find(({ creditor }) => creditor === creditorName);
        const validation = creditor?.iban ? `${creditor.iban}iban` : 'iban';

        return { [validation]: validation };
      },
    },
  };
</script>
