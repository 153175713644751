<template>
  <article class="m-t-100 align-center">
    <bb-row
      class="m-b-60"
      justify="center"
    >
      <bb-col col="auto">
        <bb-spinner class="loader" />
      </bb-col>
    </bb-row>
    <p class="m-b-15 small color-navy">
      {{ $t('views.start.title') }}
    </p>
    <p class="m-b-100 small color-navy">
      {{ $t('views.start.subTitle') }}
    </p>
    <slot></slot>
  </article>
</template>

<script>
  import { BbRow,
           BbCol,
           BbSpinner } from '@bigbank/interface-components';

  export default {
    name: 'loading',
    components: {
      BbCol,
      BbRow,
      BbSpinner,
    },
  };
</script>
