<template>
  <header class="header">
    <bb-navbar
      sticky
      flavor="public"
      :close-button="isAuthenticatedApplicant"
      :progress-bar="progressBar"
      :edit-amount="editAmount"
      logo-to="https://www.bigbank.fi/"
      @closeClick="logout"
      @editAmountClick="showCalculator = true"
    />
    <calculator
      v-if="isCalculatorFlavor"
      :visible="showCalculator"
      @close="showCalculator = false"
    />
  </header>
</template>

<script>
  import { BbNavbar } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import Calculator from './Calculator';

  export default {
    name: 'app-header',
    components: {
      BbNavbar,
      Calculator,
    },
    data() {
      return { showCalculator: false };
    },
    computed: {
      ...mapGetters('ui', [
        'isProgressFlavor',
        'isCalculatorFlavor',
        'maxPage',
        'currentPage',
      ]),
      ...mapGetters('products', ['getProductData']),
      ...mapGetters('auth', ['isAuthenticatedApplicant']),
      editAmount() {
        return this.isCalculatorFlavor
          ? {
            amount: `${this.getProductData.amount}`,
            title: this.$t('header.loanAmount'),
          }
          : undefined;
      },
      progressBar() {
        return this.isProgressFlavor
          ? {
            max: this.maxPage,
            value: this.currentPage,
            color: 'blue',
          }
          : null;
      },
    },
    methods: { ...mapActions('auth', ['logout']) },
  };
</script>

<style lang="scss" scoped>
  .divider {
    height: 40px;
    margin: 0 25px 0 20px;
    border-left: 1px solid $gray-20;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .container-ie {
      position: absolute;
      left: 25%;
      padding-top: 10px;
    }
    .progress-bar-ie {
      left: 0;
    }
  }
</style>
