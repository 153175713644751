<template>
  <bb-card
    size="fluid"
    class="p-b-15 card-calculator"
  >
    <div class="bb-card__title">
      {{ $t('components.refinancing.title') }}
    </div>
    <bb-calculator
      :amount="amount"
      :min-amount="offer.customerMinAmount"
      :max-amount="offer.customerMaxAmount"
      :amount-step="amountStep"
      :payment="monthlyPayment"
      :period="period"
      :min-period="offer.customerMinPeriod"
      :max-period="offer.customerMaxPeriod"
      :interest-rate="offer.interestRate"
      :conclusion-fee-fixed="offer.conclusionFee"
      :period-step="6"
      currency-symbol="€"
      sliders
      hide-result
      name="offer-calculator"
      :labels="{
        amount: {
          title: $t('components.calculator.loanAmountLimit')
        }
      }"
      @input="update"
    >
      <loan-amount-over-limit-alert
        v-if="alert"
        slot="amount-alert"
        :alert-text="alert"
      />
    </bb-calculator>

    <bb-container class="p-t-10 p-l-0 p-r-0">
      <bb-row>
        <bb-col>
          <bb-labeled-value
            name="calculator-used-limit"
            :value="$n(usedAmount, 'currency')"
            :label="$t('components.calculator.usedLimit')"
            label-uppercase
            color="blue"
          />
        </bb-col>
        <bb-col>
          <bb-labeled-value
            name="calculator-approx-monthly-payment"
            :value="$n(monthlyPayment, 'currency')"
            :label="$t('components.calculator.approxMonthlyPayment')"
            label-uppercase
            color="blue"
          />
        </bb-col>
      </bb-row>
      <bb-list-item
        id="conclusion-fee"
        :title="$t('offer.conclusionFee')"
        class="m-t-20"
        no-bottom-border
        condensed
      >
        <p id="conclusion-fee-val">
          {{ $n(offer.conclusionFee, 'currency-no-decimal') }}
        </p>
      </bb-list-item>
    </bb-container>
  </bb-card>
</template>

<script>
  import { BbCard,
           BbCalculator,
           BbContainer,
           BbRow,
           BbCol,
           BbListItem,
           BbLabeledValue } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import LoanAmountOverLimitAlert from '../components/LoanAmountOverLimitAlert.vue';
  import showAmountOverLimitAlert from '@/mixins/showAmountOverLimitAlert';
  import { calculateAprc, calculateMonthlyPayment } from '@bigbank/aprc-calculation';

  export default {
    name: 'liabilities-details-calculator',
    components: {
      BbCard,
      BbCalculator,
      BbContainer,
      BbRow,
      BbCol,
      BbListItem,
      BbLabeledValue,
      LoanAmountOverLimitAlert,
    },
    props: {
      offer: Object,
      refinancingAmount: Object,
      payoutConsent: Boolean,
    },
    data() {
      return {
        amount: this.$store.getters['processStep/offer'].offeredAmount,
        period: this.$store.getters['processStep/offer'].offeredPeriod,
        prevAmount: null,
        alert: '',
      };
    },
    computed: {
      ...mapGetters('products', ['getProductData']),
      usedAmount() {
        const { used } = this.refinancingAmount;
        const { conclusionFee } = this.offer;
        const maxAmount = this.amount;
        const amount = used > maxAmount ? maxAmount : used;

        return used ? amount + conclusionFee : amount;
      },
      monthlyPayment() {
        const maxMaintenanceFee = 12.5;
        const { amount, period } = this;
        const { interestRate } = this.offer;
        const { maintenanceFeeRate } = this.getProductData;

        return calculateMonthlyPayment(amount, period, interestRate, maxMaintenanceFee, maintenanceFeeRate);
      },
      amountStep() {
        return this.amount < 10000 ? 500 : 1000;
      },
    },
    watch: {
      'refinancingAmount.used'() {
        this.update();
      },
      'payoutConsent'() {
        const { amount, prevAmount } = this;
        const alert = prevAmount && prevAmount > amount ? '' : this.alert;
        this.update({ alert });
      },
    },
    created() {
      this.update();
    },
    methods: {
      update({ amount = this.amount, period = this.period, alert = '' } = {}) {
        const { prevAmount, payoutConsent, monthlyPayment } = this;
        const { customerMinAmount, customerMaxAmount } = this.offer;
        const { used } = this.refinancingAmount;

        if (used) {
          const minAmount = used < customerMinAmount ? customerMinAmount : used;
          const diff = (amount - used).toFixed(2);

          if (payoutConsent && prevAmount) {
            amount = prevAmount;
            this.prevAmount = null;
          }
          if (!payoutConsent) this.prevAmount = amount <= used ? minAmount : amount;

          if (diff > 0 && !payoutConsent && amount !== customerMinAmount) {
            amount = minAmount;
            alert = 'components.calculator.alert.refinancingAboveLimit';
          }
          if (diff < 0 && amount !== customerMaxAmount || used > customerMaxAmount) {
            amount = used > customerMaxAmount ? customerMaxAmount : minAmount;
            alert = 'components.calculator.alert.refinancingBelowLimit';
          }
          if (used < customerMinAmount) {
            alert = !payoutConsent ? 'components.calculator.alert.refinancingBelowLimitTransfer' : '';
          }
        }

        this.alert = alert;
        this.amount = amount;
        this.period = period;
        this.$emit('update', {
          amount,
          period,
          monthlyPayment: this.monthlyPayment,
          isAlert: !!this.alert,
        });
      },
    },
  };
</script>
