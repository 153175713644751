import FiLoanOriginationClient from '../../../api/FiLoanOriginationClient';
import { processStep } from '@bigbank/web-loco';

export const navigateToSigning = async (store) => {
  const externalId = store.rootGetters['processStep/externalId'];

  return FiLoanOriginationClient.getSigningLink(externalId)
    .then(({ data }) => {
      FiLoanOriginationClient.redirect(data.url);
    })
    .catch(({ response }) => {
      store.dispatch('processStep/handleError', { response }, { root: true });
    });
};

export const handleSignicatCallback = (store, { externalId, role = 'applicant', hasCoApplicant, status, carhp }) => {
  store.commit('signing/setQuery', {
    externalId,
    role,
    hasCoApplicant,
    status,
    carhp,
  }, { root: true });

  return FiLoanOriginationClient.signicatCallback(externalId, role, status)
    .catch(({ response }) => {
      store.dispatch('processStep/handleError', { response }, { root: true });
    });
};

export const handleContinue = (store) => {
  const isCoApplicant = store.rootGetters['signing/isCoApplicant'];
  const hasCoApplicant = store.rootGetters['signing/hasCoApplicant'];
  const status = store.rootGetters['signing/status'];
  const externalId = store.rootGetters['signing/externalId'];

  store.commit(`processStep/${processStep.types.LOADING}`, true, { root: true });

  if (isCoApplicant || hasCoApplicant || status === 'cancel') {
    store.dispatch('auth/logout', true, { root: true });
  } else {
    setTimeout(() => {
      // Throttling request for Camunda delegates to run
      store.dispatch('processStep/continueProcess', externalId, { root: true });
    }, 5000);
  }
};
