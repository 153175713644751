<template>
  <loading v-if="isLoading" />
  <bb-container v-else>
    <bb-row
      justify="center"
      class="p-t-45"
    >
      <bb-col
        sm="24"
        md="12"
        xl="10"
      >
        <bb-row>
          <bb-col col="23">
            <bb-product-cards
              v-if="productCards.length > 0"
              id="productCards"
              v-model="productName"
              :cards="productCards"
              :title="$t('views.chooseProduct.cardsTitle')"
              class="title--hyphens"
            />
            <product-card-content
              v-if="selectedProductCard"
              id="productMaxAmount"
              :max-amount="selectedProductCard.maxAmount"
              :sale-arguments="selectedProductCard.saleArguments"
            />
          </bb-col>
        </bb-row>
      </bb-col>
      <bb-col
        sm="24"
        md="12"
        xl="10"
        class="p-x-0"
      >
        <bb-card>
          <bb-calculator
            v-if="productCards.length > 0"
            id="calculator"
            v-bind="calculatorData"
            :amount-step="amountStep"
            :period-step="6"
            :min-amount="selectedProduct.minAmount"
            :max-amount="selectedProduct.maxAmount"
            :min-period="selectedProduct.minPeriod"
            :max-period="selectedProduct.maxPeriod"
            sliders
            class="m-b-20"
            name="choose-product-calculator"
            :payment="loanPaymentData.monthlyPayment"
            @input="onChange"
          >
            <loan-amount-over-limit-alert
              v-if="showAmountOverLimitAlert"
              slot="amount-alert"
            />
          </bb-calculator>
          <bb-list-item
            id="calculatorInterestRate"
            condensed
            :title="$t('views.chooseProduct.interestRate')"
          >
            {{ $n(selectedProduct.minInterestRate) }} %
          </bb-list-item>
          <bb-list-item
            id="calculatorAprc"
            condensed
            :title="$t('views.chooseProduct.aprc')"
          >
            {{ $n(loanPaymentData.apr) }} %
          </bb-list-item>
        </bb-card>
      </bb-col>
      <bb-col md="10">
        <bb-form
          id="formSubmitButton"
          uppercase-buttons
          :block-submit="false"
          :submit-text="$t('form.continue')"
          :error-text="$t('form.errorText')"
          :disabled="processStepLoading"
          :loading="processStepLoading"
          @submit="submit"
        />
      </bb-col>
    </bb-row>
  </bb-container>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/ui/cart';
  import '@bigbank/interface-components/dist/svg/ui/car-side';
  import '@bigbank/interface-components/dist/svg/ui/cash';
  import '@bigbank/interface-components/dist/svg/ui/paint';
  import '@bigbank/interface-components/dist/svg/ui/calculator';
  import { mapActions, mapGetters } from 'vuex';
  import Loading from '@/components/Loading';
  import { BbCalculator,
           BbCard,
           BbCol,
           BbContainer,
           BbForm, BbListItem,
           BbProductCards,
           BbRow } from '@bigbank/interface-components';
  import ProductCardContent from '@/components/ProductCardContent';
  import { calculateAprc, calculateMonthlyPayment } from '@bigbank/aprc-calculation';
  import showAmountOverLimitAlert from '@/mixins/showAmountOverLimitAlert';
  import LoanAmountOverLimitAlert from '@/components/LoanAmountOverLimitAlert.vue';

  const PRODUCT_NAME = {
    LOANFI09: 'LOANFI09',
    LOANFI10: 'LOANFI10',
    LOANFI11: 'LOANFI11',
    LOANFI12: 'LOANFI12',
    REFINANCING_LOAN_FI01: 'REFINANCING_LOAN_FI01',
  };

  const icons = {
    [PRODUCT_NAME.LOANFI09]: 'ui-cart',
    [PRODUCT_NAME.LOANFI10]: 'ui-car-side',
    [PRODUCT_NAME.LOANFI11]: 'ui-cash',
    [PRODUCT_NAME.LOANFI12]: 'ui-paint',
    [PRODUCT_NAME.REFINANCING_LOAN_FI01]: 'ui-calculator',
  };
  export default {
    name: 'choose-product',
    components: {
      BbRow,
      BbCol,
      BbContainer,
      BbForm,
      BbProductCards,
      BbCalculator,
      BbCard,
      BbListItem,
      ProductCardContent,
      Loading,
      LoanAmountOverLimitAlert,
    },
    mixins: [showAmountOverLimitAlert],
    data() {
      return {
        productName: null,
        calculatorData: {
          amount: null,
          period: null,
          interestRate: null,
        },
        loanPaymentData: {
          monthlyPayment: 0,
          apr: 0,
        },
      };
    },
    computed: {
      ...mapGetters('products', [
        'isLoading',
        'products',
      ]),
      ...mapGetters('processStep', {
        processStepLoading: 'isLoading',
        hasCoApplicant: 'hasCoApplicant',
      }),
      productCards() {
        return this.availableProducts
          .map(product => ({
            id: product.productName,
            label: this.$t(`views.chooseProduct.${product.productName}.label`),
            icon: icons[product.productName],
            maxAmount: product.maxAmount,
            saleArguments: this.$t(`views.chooseProduct.${product.productName}.saleArguments`),
          }));
      },
      availableProducts() {
        const productsList = [
          PRODUCT_NAME.LOANFI09,
          PRODUCT_NAME.LOANFI10,
          PRODUCT_NAME.LOANFI11,
          PRODUCT_NAME.LOANFI12,
          PRODUCT_NAME.REFINANCING_LOAN_FI01,
        ];

        const filtered = this.products
          .filter(product => productsList.includes(product.productName));

        if (filtered.length === 0) {
          return [];
        }

        return productsList
          .map(productName => {
            return filtered.find(f => f.productName === productName);
          });
      },
      selectedProduct() {
        return this.availableProducts.find(p => p.productName === this.productName) ?? {
          maxAmount: null,
          minAmount: null,
          maxPeriod: null,
          minPeriod: null,
        };
      },
      selectedProductCard() {
        return this.productCards.find(p => p.id === this.productName);
      },
      amountStep() {
        return this.calculatorData.amount < 10000 ? 500 : 1000;
      },
    },
    watch: {
      productName() {
        this.onChange({
          amount: this.selectedProduct.baseAmount,
          period: this.selectedProduct.basePeriod,
          interestRate: this.selectedProduct.minInterestRate,
        });
      },
    },
    async created() {
      await this.getProducts();
      this.productName = PRODUCT_NAME.LOANFI09;
    },
    methods: {
      ...mapActions('products', ['getProducts']),
      ...mapActions('processStep', ['updateProcessStep']),

      onChange(calculatorData) {
        this.calculatorData = calculatorData;
        this.calculateLoanDetails();
      },
      calculateLoanDetails() {
        const maxMaintenanceFee = 12.5;
        const monthlyPayment = calculateMonthlyPayment(this.calculatorData.amount, this.calculatorData.period, this.selectedProduct.minInterestRate, maxMaintenanceFee, this.selectedProduct.maintenanceFeeRate);
        let apr = 0;
        try {
          apr = calculateAprc(this.calculatorData.amount, this.calculatorData.period, monthlyPayment, this.selectedProduct.contractConclusionFee);
          // eslint-disable-next-line no-empty
        } catch (e) {}

        this.loanPaymentData = {
          monthlyPayment,
          apr,
        };
      },
      submit() {
        const application = {
          productType: this.selectedProduct.productType,
          initialLoanAmount: this.calculatorData.amount,
          initialLoanMaturity: this.calculatorData.period,
        };
        this.updateProcessStep({ application });
      },
    },
  };
</script>
