<template>
  <div>
    <steps-overview />
    <bb-card>
      <div slot="title">
        {{ $t(generateUploadHeading('applicant')) }}
      </div>
      <bb-banner
        type="error"
        :visible="uploadFailed"
        class="m-b-25"
        permanent
      >
        {{ $t('views.incomeProof.uploadFailed') }}
      </bb-banner>
      <upload-files
        id="upload-files-applicant"
        :files="applicantFiles"
        @update:files="applicantFiles = $event"
      />
    </bb-card>
    <bb-card
      v-if="hasCoApplicant"
      class="m-t-35"
    >
      <div slot="title">
        {{ $t(generateUploadHeading('coApplicant')) }}
      </div>
      <upload-files
        id="upload-files-coapplicant"
        :files="coApplicantFiles"
        co-applicant
        @update:files="coApplicantFiles = $event"
      />
    </bb-card>
    <bb-form
      class="bb-card bg-none no-shadow p-t-0"
      uppercase-buttons
      :submit-text="$t('views.incomeProof.submit')"
      :error-text="$t('form.errorText')"
      :disabled="isLoading || filesInvalid"
      :loading="isLoading"
      @submit="submit"
    >
      <bb-list-group class="clear-side-gutter">
        <p class="small f-medium m-b-15 m-t-35">
          {{ $t('views.incomeProof.acceptedDocuments.label') }}
        </p>
        <bb-list-item condensed>
          {{ $t('views.incomeProof.acceptedDocuments.text') }}
        </bb-list-item>
        <p class="small f-medium m-b-15 m-t-20">
          {{ $t('views.incomeProof.mustHaves.label') }}
        </p>
        <bb-list-item condensed>
          {{ $t('views.incomeProof.mustHaves.photoOrScan') }}
        </bb-list-item>
        <bb-list-item condensed>
          {{ $t('views.incomeProof.mustHaves.name') }}
        </bb-list-item>
        <bb-list-item condensed>
          {{ $t('views.incomeProof.mustHaves.period') }}
        </bb-list-item>
      </bb-list-group>
    </bb-form>
  </div>
</template>

<script>
  import { BbCard,
           BbListGroup, BbListItem, BbForm, BbBanner } from '@bigbank/interface-components';
  import StepsOverview from '../components/StepsOverview';
  import UploadFiles from '../components/UploadFiles.vue';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'upload-income-proof',
    components: {
      StepsOverview,
      BbCard,
      BbListGroup,
      BbListItem,
      BbForm,
      BbBanner,
      UploadFiles,
    },
    data() {
      return {
        applicantFiles: [],
        coApplicantFiles: [],
        uploadFailed: false,
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'hasCoApplicant',
      ]),
      filesInvalid() {
        if (this.applicantFiles.length === 0 || this.applicantFiles.some(file => file.errorFileName)) return true;
        if (this.hasCoApplicant && (this.coApplicantFiles.length === 0 || this.coApplicantFiles.some(file => file.errorFileName))) return true;

        return false;
      },
    },
    methods: {
      ...mapActions('processStep', [
        'uploadDocuments',
        'updateProcessStep',
      ]),
      async submit() {
        const applicantFiles = this.applicantFiles.map(file => file.file);
        const coApplicantFiles = this.hasCoApplicant ? this.coApplicantFiles.map(file => file.file) : [];
        const documentType = 'OTHER';

        this.uploadFailed = false;

        try {
          await this.uploadDocuments({
            files: applicantFiles,
            documentOwner: 'applicant',
            documentType,
          });

          if (this.hasCoApplicant) {
            await this.uploadDocuments({
              files: coApplicantFiles,
              documentOwner: 'coApplicant',
              documentType,
            });
          }

          this.updateProcessStep({});
        } catch (e) {
          this.uploadFailed = true;
        }
      },
      generateUploadHeading(role) {
        if (role === 'coApplicant') {
          return 'views.incomeProof.uploadDocuments.coApplicant';
        } else if (role === 'applicant' && this.hasCoApplicant) {
          return 'views.incomeProof.uploadDocuments.applicant.withCoApplicant';
        } else {
          return 'views.incomeProof.uploadDocuments.applicant.withoutCoApplicant';
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .no-shadow {
    box-shadow: none;
  }
  .bb-upload::v-deep {
    .bb-upload__heading {
      display: none;
    }
    .bb-upload__footer-cta {
      border-bottom-left-radius: .625rem;
      border-bottom-right-radius: .625rem;
    }
    .bb-upload-item {
      border: none;
    }
    .bb-upload__files {
      padding-top: 1rem;
    }
    .bb-upload-item:last-child:after {
      content: '';
      padding-top: 1rem;
      border-bottom: .0625rem solid #dee3e8;
    }
  }
  .bb-upload--for-public-use.bb-upload--has-files::v-deep {
    .bb-upload__footer-cta {
      margin-top: 1rem;
    }
  }
</style>
