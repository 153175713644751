import pick from 'lodash.pick';
import merge from 'lodash.merge';
import { processStep } from '@bigbank/web-loco';
import actions from './actions';
import { customPostHooks } from './customPostHooks';
import router from '../../../router';

// state has to include all nested keys required for the application processStep
// for Vue getters to be reactive
// Some state comes from common
const state = {
  application: {
    applicant: {
      bankAccountNumber: '',
      maritalStatus: '',
      residenceType: '',
      employment: {
        companyName: '',
        companyRegistrationCode: '',
        companyStartDate: '',
        fieldOfActivity: '',
        workStartDate: '',
        employmentContractType: '',
      },
      education: '',
      firstName: '',
      surname: '',
      personalIdentityCode: '',
      email: '',
      phone: '',
      nonPolitical: undefined,
      liabilities: undefined,
    },
    coApplicant: {
      nonPolitical: undefined,
      liabilities: undefined,
    },
    loanPurpose: '',
    offer: { decision: '' },
    marketingConsent: undefined,
    monthlyPaymentDay: undefined,
    hasCoApplicant: undefined,
    decisionReasons: [],
    calculatorData: {
      loanAmount: null,
      loanPeriod: null,
      productType: '',
    },
  },
};

const getters = {
  offer: state => state.application.offer,
  refinancingData: state => ({
    liabilities: state.application?.applicant?.liabilities ?? [],
    coApplicantLiabilities: state.application?.coApplicant?.liabilities ?? [],
  }),
  paymentData: state => ({
    marketingConsent: state.application.marketingConsent,
    bankAccountNumber: state.application.applicant?.bankAccountNumber,
    monthlyPaymentDay: state.application.monthlyPaymentDay,
  }),
  marketingConsent: state => state.application.marketingConsent,
  personalDetails: state => ({
    loanPurpose: state.application.loanPurpose,
    firstName: state.application.applicant.firstName,
    surname: state.application.applicant.surname,
    personalIdentityCode: state.application.applicant.personalIdentityCode,
    email: state.application.applicant.email,
    phone: state.application.applicant.phone,
    productType: state.application.calculatorData.productType,
  }),
  backgroundData: state => pick(state.application.applicant, [
    'maritalStatus',
    'residenceType',
    'employment',
    'education',
    'nonPolitical',
  ]),
  offerApprovedAdditionalCheck: state =>
    (state.application.offer.decision === 'ApprovedAdditionalCheck'),
  isRefinancingOffer: state =>
    (state.application.offer.decision === 'RejectedRefinance') || (state.application.offer.offeredProductType === 'REFINANCING_LOAN_FI01'),
  decisionIsRejectedBringCoBorrower: state => (state.application.offer.decision === 'RejectedBringCoBorrower'),
  nonPolitical: state => state.application.applicant.nonPolitical,
  coApplicantNonPolitical: state => state.application.coApplicant.nonPolitical,
  hasCoApplicant: state => state.application.hasCoApplicant,
  calculatorData: state => ({
    period: state.application.calculatorData.loanPeriod,
    amount: state.application.calculatorData.loanAmount,
    productType: state.application.calculatorData.productType,
  }),
  decisionReasons: state => state.application.decisionReasons,
  firstName: state => state.application.applicant.firstName,
  personalIdentityCode: state => state.application.applicant.personalIdentityCode,
};

// Allows setting mock client
export const createProcessStepModule = (client) => {
  const processModule = processStep.moduleBuilder(client, router, customPostHooks);

  return {
    ...processModule,
    state: merge(processModule.state, state),
    getters: merge(processModule.getters, getters),
    actions: merge(processModule.actions, actions),
  };
};
