import pickBy from 'lodash.pickby';

export default class GtmEvent {

  constructor({
    event = 'content-view',
    bbeventaction = 'Pageview',
    bbeventtrigger = true,
    bbeventlabel,
    bbeventcategory,
    bbapplicationid,
  }) {
    this.event = event;
    this.bbeventaction = bbeventaction;
    this.bbeventtrigger = bbeventtrigger;
    this.bbeventlabel = bbeventlabel;
    this.bbeventcategory = bbeventcategory;
    this.bbapplicationid = bbapplicationid;
  }

  filterEmptyValues() {
    return pickBy(this, value => value);
  }
}
