import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { defaultLocale, languages } from './index';
import numberFormats from './numberFormat';

Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'fi',
  silentTranslationWarn: true,
  numberFormats,
  messages,
});

export default i18n;
