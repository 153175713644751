import router from '../../../router';
import { ORIGIN_WEB, CHANNEL_SELF_SERVICE, SOURCE_BIGBANK, COUNTRY_CODE, CHANNEL_WEBPAGE } from '@/constants';
import trackingData from '../../../util/trackingData';

const LOSC_ALLOWED_ROLES = ['coApplicant'];

const metaData = () => {
  return {
    metaData: {
      tracking: {
        ...trackingData.getData(),
        ...trackingData.getCampaignData(router.currentRoute.query),
      },
    },
  };
};

const sourceData = ({ selfService } = {}) => {
  return {
    source: {
      applicationOrigin: ORIGIN_WEB,
      channel: selfService ? CHANNEL_SELF_SERVICE : CHANNEL_WEBPAGE,
      source: SOURCE_BIGBANK,
      countryCode: COUNTRY_CODE,
      ...metaData(),
    },
  };
};

const appStarted = (store) => {
  const { externalId, role, hasCoApplicant, status, carhp } = router.currentRoute.query;

  if (router.currentRoute.query.role && !LOSC_ALLOWED_ROLES.includes(router.currentRoute.query.role)) {
    router.replace({
      ...router.currentRoute,
      query: {
        ...router.currentRoute.query,
        role: undefined,
      },
    });
  }

  if (router.currentRoute.name === 'callbackSigning') {
    // Handle Signicat callback
    store.dispatch('signing/handleSignicatCallback', {
      externalId,
      role,
      hasCoApplicant,
      status,
      carhp,
    }, { root: true });
  } else if (externalId) {
    // Attempt to continue if externalId exists in query
    store.dispatch('processStep/continueProcess', externalId, { root: true });
  } else if (router.currentRoute.name === 'start') {
    // Create new application on start, after scripts (including GTM) are probably loaded
    setTimeout(() => {
      store.dispatch('processStep/createUpsaleApplication', {
        application: {
          creditConditions: { campaignCode: 'FIXED_PRICING_CAMPAIGN_02_2021' },
          ...sourceData({ selfService: true }),
        },
      }, { root: true });
    }, 200);
  } else if (router.currentRoute.name === 'newApplication') {
    store.dispatch('ui/updateUi', 'newApplication', { root: true });
  }
};

const createWebLoanApplication = ( store, { application, hCaptchaToken } ) => {
  const { amount, period } = store.rootGetters['products/getProductData'];
  const { bbchannel } = router.currentRoute.query;

  return store.dispatch('processStep/createApplication', {
    application: {
      // todo remove 1st may
      creditConditions: { campaignCode: 'FIXED_PRICING_CAMPAIGN_02_2021' },
      ...application,
      initialLoanAmount: amount,
      initialLoanMaturity: period,
      ...sourceData({ selfService: bbchannel === 'selfservice' }),
    },
    hCaptchaToken,
  },
  { root: true });
};

const updateProcessWithCalculatorData = (store, { application }) => {
  const { amount, period } = store.rootGetters['products/getProductData'];

  return store.dispatch('processStep/updateProcessStep', {
    application: {
      ...application,
      offerChange: {
        loanAmount: amount,
        loanMaturity: period,
      },
    },
  }, { root: true });
};

export default {
  appStarted,
  createWebLoanApplication,
  updateProcessWithCalculatorData,
};
