export const trimRange = (val, min, max) => val < min
  ? min
  : val > max
    ? max
    : val;

export const ibanInput = (val) => (typeof val !== 'string' || val === '')
  ? undefined
  : val.replace(/\s/g, '');

export const paymentDescriptionInput = (val) => (typeof val !== 'string' || val === '')
  ? undefined
  : val.replace(/\\|\<|\>/g, '');

export const referenceNumberInput = (val) => (typeof val !== 'string' || val === '')
  ? undefined
  : val.replace(/[^0-9]/g, '');
