import * as actions from './actions';

const state = {
  role: '',
  hasCoApplicant: false,
  externalId: '',
  status: '',
  carhp: false,
};

const getters = {
  role: state => state.role === 'selfServiceUser' || state.role === 'applicant' ? 'applicant' : 'coApplicant',
  isApplicant: (state, getters) => getters.role === 'applicant' ? true : false,
  isCoApplicant: (state, getters) => getters.role === 'coApplicant' ? true : false,
  isCarhp: (state) => state.carhp,
  hasCoApplicant: state => state.hasCoApplicant === true,
  externalId: state => state.externalId,
  status: state => state.status === 'cancel' ? 'cancel' : 'complete',
  textType: (state, getters) => {
    if (getters.isCarhp) {
      return 'carhp';
    } else if (getters.status === 'cancel') {
      return 'cancel';
    } else if (getters.isApplicant && getters.hasCoApplicant) {
      return 'applicantWithCoApplicant';
    } else if (getters.isApplicant && !getters.hasCoApplicant) {
      return 'applicantWithoutCoApplicant';
    } else {
      return 'coApplicant';
    }
  },
};

const mutations = {
  setQuery: (state, { externalId, role, hasCoApplicant, status, carhp }) => {
    state.externalId = externalId;
    state.role = role;
    state.status = status;
    state.hasCoApplicant = hasCoApplicant === 'true' ? true : false;
    state.carhp = carhp === 'true' ? true : false;
  },
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
  actions,
};
