<template>
  <start />
</template>

<script>
  import Start from '../Start';
  import { mapActions } from 'vuex';

  export default {
    name: 'navigate-to-signing',
    components: { Start },
    created() {
      this.navigateToSigning();
    },
    methods: { ...mapActions('signing', ['navigateToSigning']) },
  };
</script>

